import React, { useCallback, useEffect, useState } from 'react'
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    message,
    Upload,
    Image
} from 'antd';

const Page = ({
    value,
    onChange,
    maxCount = 1,
    imageHeight = 100,
    readOnly = false,
    ...nextProps
}) => {

    const [fileName, setFileName] = useState('');

    const downloadFile = (fileCode) => {
        post('/api/app/file/v1.0/file/download', {
            fileCode: fileCode
        },).then((res) => {
            window.open(res.data);
        })
    }

    useEffect(() => {
    }, [value])

    return (
        <div style={{ display: 'flex', alignItems: "center" }}>

            <Upload
                action={'/api/app/file/v1.0/file/upload'}
                showUploadList={false}
                maxCount={maxCount}
                name='file'
                onChange={(info) => {
                    if (info.file.status === 'done') {
                        if (maxCount == 1) {
                            setFileName(info.file.name);
                            onChange(info.file.response.data, info.file.name);
                        } else {
                            const newValue = [];
                            info.fileList.map((file) => {
                                newValue.push(file.response.data);
                            })
                            onChange(newValue, info.file.name);
                        }
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} 上传失败`);
                    }
                }}
                {...nextProps}
            >
                {!readOnly && <Button icon={<UploadOutlined />}>选择文件</Button>}
            </Upload>
            &nbsp;&nbsp;
            <div>
                {maxCount == 1 && value != null && value != '' && (
                    <a onClick={() => {
                        downloadFile(value);
                    }} >{fileName ? fileName : "下载"}</a>
                )}
                {maxCount > 1 && value != null && (
                    value.map((v) => {
                        return <a key={v} onClick={() => {
                            downloadFile(value);
                        }}>{v}</a>
                    })
                )}
            </div>
        </div>)
}

export default Page;