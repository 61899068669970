import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Form, NavBar, Input, Toast, Radio } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import DictPickerItem from 'component/DictPickerItem'
import ImageUpload from 'component/ImageUpload'
import NumberInput from 'component/NumberInput'
import WeixinUtil from 'util/weixin';
import Url from 'util/url';
import Privacy from './privacy'
import Aggrement from './agreement';
function Page() {

    const [product, setProduct] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const [agreementChecked, setAgreementChecked] = useState(false);

    const [lawyerOrder, setLawyerOrder] = useState({})

    const navigate = useNavigate();
    const [pageForm] = Form.useForm()



    useEffect(() => {
        WeixinUtil.jsConfig();

        post('/api/ec/public/product/get', {
            productCode: "AilawyerLawyer"
        }).then(res => {
            setProduct(res.data);
        })

    }, [])



    const submitForm = () => {
        pageForm.validateFields().then((data) => {
            if (!agreementChecked) {
                Toast.show("请阅读并同意用户协议！");
                return;
            } else {
                post('/api/ailawyer/public/lawyer/apply/create', data).then(res => {
                    WeixinUtil.goPay(res.data.code, function (orderCode) {
                        Toast.show({
                            content: '支付完成！',
                        })
                        navigate(-1);
                    })
                })
            }

        })

    }


    return (
        <div className="lawyer-page">
            {/* <NavBar onBack={() => { navigate(-1) }}>律师账号开通</NavBar> */}
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='success' color='success' size='middle'
                            onClick={() => {
                                submitForm()
                            }}
                            style={{ marginTop: 10 }}>
                            确认开通
                        </Button>
                        <div className="aggrement-privacy">
                            <Radio checked={agreementChecked} onChange={(v) => { setAgreementChecked(v) }}>已阅读及同意

                            </Radio>
                            <a className="s2" onClick={() => {
                                Url.openPage(<Aggrement></Aggrement>);
                            }}>《律伴用户协议》</a>&nbsp;
                            <a className="s2" onClick={() => {
                                Url.openPage(<Privacy></Privacy>);
                            }}>《隐私政策》</a>
                        </div>

                    </>
                }
            >
                <List header=''>

                    <List.Item extra={<Form.Item name='name'
                        rules={[{ required: true, message: '请输入姓名' }]}>
                        <Input className='text-right' placeholder='请输入姓名' />
                    </Form.Item>} >
                        姓名
                    </List.Item>


                    <List.Item extra={<Form.Item name='companyName'
                        rules={[{ required: true, message: '请输入所属律所' }]}>
                        <Input className='text-right' placeholder='请输入所属律所' />
                    </Form.Item>} >
                        所属律所
                    </List.Item>

                    <List.Item extra={<Form.Item name='certificateCode'
                        rules={[{ required: true, message: '请输入执业证号' }]}>
                        <Input className='text-right' placeholder='请输入执业证号' />
                    </Form.Item>} >
                        执业证号
                    </List.Item>

                    <List.Item extra={<Form.Item name='certificateImage'
                        rules={[{ required: true, message: '请上传执业证' }]}>
                        <ImageUpload placeholder='请上传执业证' />
                    </Form.Item>} >
                        执业证
                    </List.Item>

                    <List.Item extra={<Form.Item name='idCardCode'
                        rules={[{ required: true, message: '请输入身份证号' }]}>
                        <Input className='text-right' placeholder='请输入身份证号' />
                    </Form.Item>} >
                        身份证号
                    </List.Item>

                    <List.Item extra={<Form.Item name='idCardImage'
                        rules={[{ required: true, message: '请上传身份证' }]}>
                        <ImageUpload placeholder='请上传身份证' />
                    </Form.Item>} >
                        身份证
                    </List.Item>

                </List>

                <List header='' style={{ marginTop: 10 }}>

                    <List.Item extra={<span style={{ paddingRight: 10 }}>{product.price}元/月</span>} >
                        平台使用费用
                    </List.Item>


                    <List.Item extra={<Form.Item name='number'
                        rules={[{ required: true, message: '请输入开通时长' }]}>
                        <NumberInput minValue={1} initialValues={0} onChange={(v) => {
                            setTotalPrice(v * product.price);
                        }} />
                    </Form.Item>} >
                        开通时长(月)
                    </List.Item>

                    <List.Item extra={<span style={{ paddingRight: 10 }}>{totalPrice}元</span>} >
                        费用合计
                    </List.Item>



                </List>
            </Form >
            {/* <div className="pd-20">
                <Button color='success' onClick={() => { submitForm() }} block>确认开通</Button>
            </div> */}

        </div>
    );
}
addPage("/ailawyer/mine/lawyer/open", Page);
export default Page;
