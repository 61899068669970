import React, { useState } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, Ellipsis } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import CustomNavbar from '../navbar/index'
import { format } from 'util/date'

import './index.css'

function Page() {

    const metaCode = "ailawyerLawyer";
    const navigate = useNavigate();

    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        params: [{
            attrCode: "status",
            operator: "=",
            value: "normal"
        }, {
            attrCode: "isPublic",
            operator: "=",
            value: "true"
        }],
        orders: [{
            attrCode: "lastMonthUse",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });

    const goDetail = (lawyerCode) => {
        navigate("/ailawyer/lawyer/detail?code=" + lawyerCode);
    }

    const [robotData, setRobotData] = useState({
        records: []
    });

    return (
        <div className="lawyer-page lawyer-lawyer-page" >
            {/* <NavBar backArrow={null} >律师</NavBar> */}
            <ScrollList
                url={'/api/app/public/meta/data/search'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(lawyer => (

                        <div className="lawyer-item" key={lawyer.id} onClick={() => {
                            goDetail(lawyer.code);
                        }}>

                            <div className="t1">
                                <div className="flex-row s1">
                                    <img src={"/api/ac/public/headimg/" + lawyer.creatorCode} className="headimg" />
                                    <div className="flex-row flex-1 ml-10 flex-justify-between">
                                        <div className='flex-row '>
                                            <div className="location-icon ml-5"></div>
                                            <div className="font-12 ">{lawyer.regionName ? lawyer.regionName : "全国"}</div>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-row">本月服务<span className="font-13 font-bold ml-5">{lawyer.lastMonthUse}</span></div>
                                            <div className="flex-row ml-10">累计服务<span className="font-13 font-bold ml-5">{lawyer.totalUse}</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div className="s2 flex-row justify-between">
                                    <div className="flex-row">
                                        <div className="font-16">{lawyer.name}</div>
                                        <div className="s">{lawyer.companyName}</div>
                                    </div>
                                    <div className="flex-row">
                                        <div className="icon-date"></div>
                                        <span className="title-date">{format(lawyer.createTime, 'YYYY-MM-DD')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="t2">
                                <Ellipsis direction='end' rows={2} content={lawyer.remark} />
                            </div>
                            <div className="t3">
                                {lawyer.protocolNames && lawyer.protocolNames.split(",").map((protocolName, index) => (
                                    index <= 2 && <div className="tag" key={protocolName}>{protocolName}</div>
                                ))}
                            </div>
                        </div>
                    ))
                }

            </ScrollList>
            <CustomNavbar />
        </div>
    );
}
addRoute("/ailawyer/lawyer", Page);
export default Page;