import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Input, List, Button, Form, Modal, Ellipsis, Toast, Popover, Empty, Swiper } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import ImageUpload from 'component/ImageUpload'
import RichTextEditor from 'component/RichTextEditor'

import moment from 'moment';
import Url from 'util/url';


function Page() {

    const navigate = useNavigate();
    const [pageForm] = Form.useForm()

    const [showAddBtns, setShowAddBtns] = useState(false);

    const [activityParam, setActivityParam] = useState({
        metaCode: "cmsArticle",
        params: [{
            attrCode: "channelCode",
            value: "lawyerActivity",
            operator: "="
        }],
        orders: [{
            attrCode: "publishTime",
            order: "desc"
        }]
    });

    const [honorData, setHonorData] = useState({
        records: []
    });

    const [activityData, setActivityData] = useState({
        records: []
    });

    useEffect(() => {
        // post('/api/ac/my/account/attr/get', {
        //     metaCode: "lawyerImages"
        // }).then(res => {
        //     pageForm.setFieldsValue({
        //         lawyerImages: res.data
        //     });
        // })

        post('/api/app/my/meta/data/search', {
            metaCode: "cmsArticle",
            params: [{
                attrCode: "channelCode",
                value: "lawyerHonor",
                operator: "="
            }],
            orders: []
        }).then(res => {
            setHonorData(res.data);
        });
    }, [])

    return (
        <div className="laywer-mine-laywer-account-news">
            {/* <NavBar onBack={() => { navigate("/ailawyer/mine") }}>相册动态</NavBar> */}

            <div className="image-wrapper">
                <Swiper slideSize={50}

                    indicator={(total, current) => (
                        <div className="customIndicator">
                            {`${current + 1} / ${total}`}
                        </div>
                    )}
                    trackOffset={0} stuckAtBoundary={false}>{
                        honorData.records.map((item, index) => {
                            return <Swiper.Item key={item.code} className='image-item' onClick={() => {
                                navigate("/ailawyer/mine/lawyer/news/honor?id=" + item.id);
                            }}>
                                <img src={item.titleImg}></img>
                                <div className='image-title'>{item.title}</div>
                            </Swiper.Item>
                        })
                    }</Swiper>
                {honorData.records.length == 0 && <Empty
                    style={{ padding: '64px 0' }}
                    imageStyle={{ width: 128 }}
                    description='风采照片为空'
                />}
            </div>

            <div className="news-title">最新动态</div>


            <ScrollList className="mt-20 news-wrapper"
                url={'/api/app/my/meta/data/search'}
                param={activityParam}
                setParam={setActivityParam}
                data={activityData}
                setData={setActivityData}
            >
                {
                    activityData.records.map(news => (

                        <List.Item
                            key={news.id}
                        >
                            <div className="news-item  " onClick={() => {
                                navigate("/ailawyer/mine/lawyer/news/activity?id=" + news.id);
                            }}>
                                <div className="font-14 news-item-time"  >
                                    <div className="circle"></div>
                                    <div className="line"></div>
                                    {moment(news.publishTime).format('YYYY-MM-DD')}
                                </div>
                                <Ellipsis direction='end' rows={2} content={news.title} className="news-item-title" />
                            </div>
                        </List.Item>
                    ))
                }
                <div onClick={() => {
                    // 
                }}></div>
            </ScrollList>

            {showAddBtns && <>
                <div className="mask-bg" onClick={() => {
                    setShowAddBtns(false);
                }}></div>
                <div className="add-btns">
                    <div className='flex-row font-15 lh-40'
                        onClick={() => {
                            navigate("/ailawyer/mine/lawyer/news/honor");
                        }}><div className="add-img-icon "></div><div className="ml-10">添加照片</div></div>
                    <div className='flex-row font-15 lh-40' onClick={() => {
                        navigate("/ailawyer/mine/lawyer/news/activity");
                    }}><div className="add-news-icon"></div><div className="ml-10">添加动态</div></div>
                </div></>}
            <div className="add-icon" onClick={() => {
                setShowAddBtns(!showAddBtns);
            }}></div>
        </div >
    );
}
export default Page;
