import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ShareCanvas from 'component/ShareCanvas';
import { SpinLoading, Mask } from 'antd-mobile'
import { FaRegThumbsUp, FaThumbsUp, FaVoicemail } from 'react-icons/fa';
import { post } from 'util/http';

import './index.css'
import { format } from 'util/date';
function Page({
    lawyer,
    showEndTime = false,
    showLastMonthUse = true,
    showTotalUse = true,
    showLike = true
}) {

    const share = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // const [lawyer, setLawyer] = useState({});


    const [lawyerLike, setLawyerLike] = useState(false);
    const [likeCount, setLikeCount] = useState(0);

    const createLawyerLike = () => {
        post("/api/cms/my/like/create", {
            targetType: "LawyerLawyer",
            targetCode: lawyer.code,
        }).then(res => {
            setLawyerLike(true)
            setLikeCount(likeCount + 1)
        })
    }

    const deleteLawyerLike = () => {
        post("/api/cms/my/like/delete", {
            targetType: "LawyerLawyer",
            targetCode: lawyer.code,
        }).then(res => {
            setLawyerLike(false)
            setLikeCount(likeCount - 1)
        })
    }

    const getLawyerLike = (laywerCode) => {
        post("/api/cms/my/like/get", {
            targetType: "LawyerLawyer",
            targetCode: laywerCode
        }).then(res => {
            setLawyerLike(res.data.creatorCode != '')
        })
    }

    const shareLawyer = async () => {
        var startX = 30;
        var startY = 20;
        var headWidth = 60;
        var headHeight = 60;

        setLoading(true);
        share.current.fillRoundRect(0, 0, share.current.getSourceWidth(), share.current.getSourceHeight(), 10, "#D9E8E4")
        share.current.fillRoundRect(15, 39, share.current.getSourceWidth() - 30, share.current.getSourceHeight() - 78, 10, "#ffffff")
        await share.current.drawRoundImg("/api/ac/public/headimg/" + lawyer.creatorCode, startX, startY, headWidth, headHeight, 30)
        await share.current.drawImg("https://static-1306521133.cos.ap-beijing.myqcloud.com/xcloud/xcloud-mobile/build/static/images/ailawyer/location.png", startX + headWidth + 5, 53, 15, 15)
        share.current.fillText(lawyer.regionName ? lawyer.regionName : "全国", startX + headWidth + 20, 65, "#000000", 12);
        share.current.fillText(lawyer.name, startX, 120, "#000000", 18);
        share.current.fillTag(lawyer.companyName, 100, 102, "#10A37F", "#EAF3F1", 12);
        share.current.wrapFillText(lawyer.remark, startX, 150, "#000000", 13, 300, 22);
        var nextX = startX;
        var protocolCount = 0;
        lawyer.protocolNames && lawyer.protocolNames.split(",").map((item, index) => {
            if (protocolCount++ < 3) {
                share.current.fillTag(item, nextX, 192, "#3D445B", "#F6F6F6", 12);
                nextX = nextX + share.current.getTextLength(item, 12) + 40;
            }
        })
        share.current.fillText("本月服务", startX, 240, "#000000", 12);
        share.current.fillText(lawyer.lastMonthUse, startX + 60, 241, "#000000", 14);
        share.current.fillText("累计服务", startX + 90, 240, "#000000", 12);
        share.current.fillText(lawyer.totalUse, startX + 150, 241, "#000000", 14);
        share.current.fillText("@律伴AI", startX, 305, "#1A1A1A ", 16);
        share.current.fillText("长按识别二维码", startX, 335, "#626262", 12);
        share.current.fillText("获得专业AI律师助理", 120, 335, "#626262", 12);
        await share.current.fillQrcode(window.APP_CONFIG.h5Domain + "/ailawyer/lawyer/detail?code=" + lawyer.code, 250, 290, 80, 80)
        share.current.transferToImg();
        share.current.show();
        setLoading(false);
    }

    useEffect(() => {
        if (lawyer != null && lawyer.code) {
            getLawyerLike(lawyer.code)
        }
    }, [lawyer])

    return (
        <div>
            <div className="lawyer-item" key={lawyer.creatorCode}>

                <div className="t1">
                    <div className="flex-row s1">
                        <img src={"/api/ac/public/headimg/" + lawyer.creatorCode} className="headimg" />
                        <div className="flex-row flex-1 justify-between">
                            <div className='flex-row ml-10'>
                                <div className="location-icon"></div>
                                <div className="font-12 ">{lawyer.regionName ? lawyer.regionName : "全国"}</div>
                            </div>
                            <div className="flex-row">
                                {showLastMonthUse && <div className="flex-row font-14">本月服务<span className="font-13 font-bold ml-5">{lawyer.lastMonthUse}</span></div>}
                                {showTotalUse && <div className="flex-row ml-10 font-14">累计服务<span className="font-13 font-bold ml-5">{lawyer.totalUse}</span></div>}
                                {showEndTime && <div className="flex-row ml-10 font-14" onClick={() => {
                                    navigate("/ailawyer/mine/lawyer/account")
                                }}>有效期<span className="font-13 font-bold ml-5">{format(lawyer.endTime, 'YYYY-MM-DD')}</span></div>}
                            </div>
                        </div>
                    </div>
                    <div className="s2 flex-row justify-between">
                        <div className="flex-row">
                            <div>{lawyer.name}</div>
                            <div className="s">{lawyer.companyName}</div>
                        </div>
                        {/* <span className="font-12">{moment(lawyer.createTime).format('YYYY-MM-DD')}</span> */}
                        {/* <div className="share-icon" onClick={() => {
                            shareLawyer()
                        }}></div> */}
                    </div>
                    <div className="s3 flex-row-reverse-right  ">

                        <div className="share-icon ml-40" onClick={() => {
                            shareLawyer()
                        }}></div>
                        {/* {showLike && lawyerLike && <div className="flex-row   color-green" onClick={() => {
                            deleteLawyerLike()
                        }}>
                            <FaRegThumbsUp className="font-15 mr-5" />
                            {likeCount}
                        </div>}
                        {showLike && !lawyerLike && <div className="flex-row   " onClick={() => {
                            createLawyerLike()
                        }}>
                            <FaRegThumbsUp className="font-15  mr-5" />
                            {likeCount}
                        </div>} */}



                    </div>
                </div>

            </div>

            <Mask visible={loading} className="flex-column" style={{ textAlign: 'center', color: "#ffffff" }}>
                <SpinLoading style={{ '--size': '48px', margin: '20rem auto 0' }} color={"#ffffff"} />
                <div style={{ margin: "1rem 0 0  0 " }}>加载中...</div>
            </Mask>

            <ShareCanvas ref={share} height={440} width={window.innerWidth - 20} />

        </div >
    );
}
export default Page;
