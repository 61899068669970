
import React from 'react'
import { NavBar } from 'antd-mobile'
import Url from 'util/url';

const Page = ({
}) => {

    return (
        <div className='no-scrool'>
            <NavBar onBack={() => {
                Url.closePage();
            }}>隐私政策</NavBar>

            <div className="pd-10-20 scroll-body-page ">
                <b>本隐私政策旨在说明在你使用北京未来有伴科技有限公司（以下简称“我们”）产品/服务时，我们如何收集、使用、保存、共享和转让你的个人信息，以及你对你的个人信息享有的相关权利等。本隐私政策是《用户服务协议》的组成部分，与其具有同等法律效力。</b><br /><br />
                特别提示<br /><br />
                律伴（也称“律伴平台”）是一款由北京未来有伴科技有限公司（注册地为北京市怀柔区迎宾南路11号五幢二层2213室）提供服务的产品，具体包括律伴网站（https://lawyer.youbanai.com/）、律伴客户端应用程序（如律伴App）等网站及软件，以及该网站及软件上为你提供的各种服务。为说明律伴会如何收集、使用和存储你的个人信息及你享有何种权利，我们将通过本隐私政策向你阐述相关事宜。我们向你特别提示以下内容：<br /><br />
                1.本隐私政策由你与北京未来有伴科技有限公司在北京市朝阳区共同签署。<br /><br />
                2.在签署本隐私政策前，请你务必审慎阅读、充分理解各条款的内容，尤其是免除或限制责任/权利的条款、法律适用与争议解决条款，或者其他以下划线/粗体/倾斜/高亮等形式特别标识的条款。如你对本隐私政策有任何疑问的，你可通过本隐私政策第9条中的联系方式与我们联系，我们根据本隐私政策为你作出解答。如果你对本隐私政策的条款内容存有异议的，你可以选择不接受或者不使用律伴服务；如果你选择接受、使用律伴服务的，将视为你接受、认可并同意按照本隐私政策。<br /><br />
                3.你同意本隐私政策，使用律伴服务，即表明你已知晓并认可我们将会对你在律伴上发布的法律咨询或法律服务要求，推送给律伴合作的律师进行解答或提供服务。由于法律问题的特殊性及复杂性，律伴的合作律师可能会通过电话、微信等通讯方式询问并解答问题，你同意你的电话及微信等通讯方式授权给可能为你提供服务的律师。<br /><br />
                4.当你使用律伴某些功能时，我们会在获得你的同意后，收集你的敏感个人信息，但按照相关法律法规要求必须收集的除外。拒绝提供这些信息会使你无法使用相关律伴的某些功能，但不影响你正常使用律伴的基础性服务功能。<br /><br />
                为使你更便利地了解本隐私政策，请根据以下目录阅读相应条款：<br /><br />
                1.收集、使用信息<br /><br />
                2.信息存储<br /><br />
                3.信息安全<br /><br />
                4.信息共享<br /><br />
                5.你的权利<br /><br />
                6.变更<br /><br />
                7.未成年人保护<br /><br />
                8.另行告知<br /><br />
                9.联系我们<br /><br />
                10.争议解决<br /><br />
                <br /><br />
                1.收集、使用信息<br /><br />
                在你使用律伴的过程中，我们遵循法律规定的正当、合法、必要等原则，按照如下方式收集、使用你在使用律伴服务时主动提供或因为使用服务而产生的信息：<br /><br />
                1.1当你在律伴平台注册时，我们会收集、使用你的账户名、密码、头像、手机号码，你的账户名为你的默认昵称。收集这些信息是为了帮助你完成注册和使用律伴的功能，保护你的律伴帐号安全。收集、使用手机号码是为了满足相关法律法规的真实身份信息认证要求。若你不提供这类信息，你可能无法正常使用我们的服务。你还可以根据自身需求选择填写性别、地区等信息。此外，你可以选择向我们提供你的声纹特征值信息、面部特征值信息，拒绝提供该信息仅会使你无法使用相关功能，但不影响你正常使用律伴的其他功能。<br /><br />
                1.2当你登录律伴平台时，你需要向我们提供你的律伴账号信息。如果是通过微信等其他第三方平台登录律伴的，我们可能会收集你的第三方平台的账号信息，以便为你提供安全的服务。<br /><br />
                1.3当你使用律伴的浏览、查找和收藏功能时，你可在律伴平台上浏览律伴服务（如律师介绍）信息，搜索、查找律伴合作律师以及其他服务人员信息。为了方便你快速找到相关服务，我们可能会收集你使用律伴产品/服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID等软硬件信息）、浏览器类型，来为你提供律伴服务信息展示的最优方式。在你使用浏览和收藏功能过程中，我们可能自动收集你使用律伴的详细情况，并作为网络日志保存，包括但不限于你搜索或查找的关键词记录、点击链接记录、你浏览的内容及信息、访问的日期和时间、以及你请求的网页记录、操作系统、软件版本、登录IP信息，同时包括你的浏览记录。<br /><br />
                1.4当你使用律伴的咨询和法律文书制作功能时，我们可能会收集你填写的案件信息、上传的图文信息、咨询的案件类型、你的手机号码。你填写的案件信息、上传的图文信息可能包含你的其他个人信息（如你的姓名、性别、出生日期、身份证号码、住址、财产信息等），除非你的服务必须提供和使用这些信息，否则，你不用向律伴提供额外的个人信息。<br /><br />
                为提高服务质量，律伴平台及律伴平台合作律师可能根据实际情况收集你的具体案情信息。为向你交付相应产品/服务成果，律伴服务人员或合作律师可能根据实际情况收集你的身份信息和联系信息，包括你的姓名、电子邮箱、电话号码、社交账号（如微信账号）、联系地址。上述案情信息可能包含你的其他个人信息（如你的姓名、性别、出生日期、身份证号码、住址、财产信息等），除非你的服务必须提供和使用这些信息，否则你可在使用匿名、去标识、内容替换等方式处理相关信息后再将其提供给律伴，以便你不用向律伴提供额外的个人信息。<br /><br />
                特别提示：你在律伴平台使用法律咨询和法律文书制作功能时，应当尽量避免出现其他人的个人信息（如他人的真实姓名、性别、出生日期、身份证号码、住址、财产信息等），若你的服务必须提供和使用这些信息，请你使用匿名、去标识、内容替换等方式将相关信息进行处理后再将其提供给律伴，否则，由此而给他人造成的全部损失和后果，由你承担全部责任和后果。<br /><br />
                1.5 当你使用律伴的支付功能时，可选择第三方支付机构（包含支付宝支付和微信支付）提供的支付服务完成支付。在此过程中，我们可能会收集你的第三支付账号信息（如支付宝账号、微信账号），同时我们需要将你的订单信息（如订单号、订单金额）与相关第三方支付机构共享以实现确认你的支付指令并完成支付。若你需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。<br /><br />
                1.6 当你使用律伴提供的实用工具时，为了应用工具进行相关费用计算（如利息、违约金、诉讼费等），我们可能会收集你填写的诉讼标的金额、利率等相关数据和信息。如果你填写相关数据和信息不准确，则该实工具无法使用或因此计算的结果不准确。、<br /><br />
                1.7当你使用律伴提供的客服与售后功能时，你可向律伴客服和售后联系方式反映你在使用律伴服务中遇到的疑问、以及你对律伴的意见或建议，我们需要你提供你的账号信息和订单信息。同时，为保证你的账号安全，我们可能还会核查你的身份信息，要求你提供你的姓名、身份证件号码等信息，以便我们查询和处理你的订单。<br /><br />
                1.8 当你使用律伴某些功能时，律伴需要你提供以下信息，若你不提供，不影响你正常使用律伴的基础功能，但某些功能将无法使用：（1）若你需要推荐你所在区域可以选择的律伴合作律师时，我们可能会收集你的位置信息；（2）若你在使用律伴时需要上传图文或拍摄照片时，我们会访问你的设备及文件信息并进行收集；（3）若你发起语音咨询或需要在服务时使用语音的，我们会访问或收集你的设备的录音信息。你可以在你的设备中逐项查看上述权限的开启状态，并可决定将这些权限随时的开启或关闭。<br /><br />
                1.9 若你通过第三方进入律伴平台或使用律伴服务功能时，我们可能从第三方获取你共享的账户信息、联系信息、交易信息等，并在你同意本隐私政策后将你的第三方账户与你的律伴账户绑定。我们会根据与第三方的约定，在符合法律法规的前提下，使用你的个人信息。<br /><br />
                2.信息存储<br /><br />
                2.1 信息存储的地点<br /><br />
                我们根据法律法规规定，将境内收集的用户个人信息存储于中国境内。如果你的个人信息存储地点从中国境内转移到境外的，我们将严格依照法律的规定执行。<br /><br />
                2.2 信息存储的期限<br /><br />
                我们按法律规定或在合理必要期限内存储你的个人信息。对于超出期限的个人信息，我们会立即删除或做匿名化处理。<br /><br />
                3.信息安全<br /><br />
                我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、篡改、未经授权访问或披露。<br /><br />
                我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会采取适当的加密技术（例如，SSL）、去标识化、匿名化处理等手段来保护你的个人信息。<br /><br />
                我们通过不断提升的技术手段加强安装在你设备端的软件的安全能力，以防止你的个人信息泄露。例如，我们为了安全传输会在你设备本地完成部分信息加密的工作；为了预防病毒、木马程序或其他恶意程序、网站，我们可能会了解你设备安装的应用信息、正在运行的进程信息或设备内存中寄存的数据等。<br /><br />
                若发生个人信息泄露等安全事件，我们会启动应急预案及补救措施，阻止安全事件扩大，并依法履行相关的报告及通知义务。<br /><br />
                我们会尽力保护你的个人信息。我们也请你理解，任何安全措施都无法做到无懈可击。<br /><br />
                4.信息共享<br /><br />
                仅在下列情况下，我们可能会与第三方共享你的个人信息：<br /><br />
                （1）事先获得你明确的同意或授权；<br /><br />
                （2）根据法律法规要求、强制性的行政或司法机构要求必须提供；<br /><br />
                （3）需要共享你的个人信息，才能实现律伴服务的核心业务功能或提供你需要的服务，比如，将你的相关信息提供给律伴合作律师事务所或律师，以便于合作律师事务所或律师开展服务；<br /><br />
                （4）基于符合法律法规的社会公共利益而使用；<br /><br />
                （5）法律法规规定的其他情形。<br /><br />
                特别提示：你已知晓并认可律伴平台将会对你在律伴平台发布的法律服务需求，推送给律伴平台合作律师。你知晓并理解，由于法律问题的特殊性及复杂性，律伴平台的合作律师将会通过电话、微信等通讯方式询问并提供法律服务，你同意将你的电话、微信等通讯方式授权给可能为你提供服务的律师。<br /><br />
                5.你的权利<br /><br />
                你有权随时访问、更正、删除你的个人信息，你也可以通过删除信息、关闭设备功能、关闭系统权限等方式改变你授权我们继续收集个人信息的范围或撤回你的授权或同意。你也可以通过注销账户的方式，撤回我们收集你个人信息的全部授权。特别提醒，你的前述操作可能不会立即在我们的备份系统中删除相应的信息，但会在备份时删除这些信息或作匿名化处理。<br /><br />
                6.变更<br /><br />
                我们可能会对本隐私政策进行修订。当音系政策条款发生重大变更时，我们会在你登录及版本更新时以推送通知、弹窗或其他符合法律要求的适当形式向你展示更新后的政策。<br /><br />
                7.未成年人保护<br /><br />
                根据《中华人民共和国民法典》等相关法律法规，若你是18周岁以下的未成年人，在使用律伴前，你应请你父母或监护人仔细阅读本隐私政策。如果你是八周岁以上的未成年人或不能完全辨认自己行为的成年人，请征得你父母或监护人对本隐私政策的同意。<br /><br />
                如你是未成年人或是不能完全辨认自己的行为，且未获得父母或者其他监护人的同意，你应立即停止注册、登录或使用律伴产品/服务，并注销其他已注册的账户。<br /><br />
                若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有相关疑问时，请通过本隐私政策第9条中的联系方式与我们联系。<br /><br />
                8.另行告知<br /><br />
                在律伴某些具体服务或功能项下，我们可能还会通过弹窗、交互文案或产品界面等方式向你另行告知该项服务或功能处理个人信息的目的、方式或范围，若另行告知的内容与本政策不一致的，以另行告知的内容为准。<br /><br />
                9.联系我们<br /><br />
                如果你对本隐私政策或个人信息保护相关事宜有任何疑问或投诉、建议时，你可以通过以下任一方式与我们联系：<br /><br />
                （1）通过https://lawyer.youbanai.com/（网址）与我们联系；<br /><br />
                （2）通过【                      】邮箱与我们联系；<br /><br />
                我们会尽快审核相关问题，在收到你的投诉反馈后的十五日内进行回复。<br /><br />
                10.争议解决<br /><br />
                10.1就本隐私政策所发生的争议，若协商无法解决的，应将争议提交至北京未来有伴科技有限公司所在地有管辖权的法院诉讼解决。<br /><br />
                10.2有关法律文书的送达：对于因本协议争议而引致的任何纠纷，你同意审判机关按照你在律伴平台注册账号时所提供的信息（包括但不限于邮箱、手机号码、微信、QQ、联系地址等内容）进行法律文书（包括但不限于权利申请类型文件【例如起诉状、反诉状、仲裁申请书、保全申请书等】的正副本、证据、审判机关及相关评估、鉴定机构出具的文书等）送达，审判机关可以采取以上一种或多种方式送达，送达时间以最先送达的为准。你确认，上述送达方式适用于整个争议解决阶段；若你所提供的送达信息不确切、或不及时告知变更的，使法律文书无法送达或未及时送达，由此产生的不利后果将由你自行承担。
            </div>

        </div>
    );
}
export default Page;
