const ImgUtil = {
    getHeadimg: (code) => {
        if (code == null && code == '') {
            return "";
        } else {
            return "/api/ac/public/headimg/" + code;
        }
    },
    getRobotHeadimg: (code) => {
        if (code == null && code == '') {
            return "";
        } else {
            return "/api/ailawyer/public/robot/headimg/" + code;
        }
    }
}

export default ImgUtil;