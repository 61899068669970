import { Toast } from "antd-mobile";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import AuthUtil from "./auth";
const parseResponse = (res, success, fail) => {
    if (res.status <= 0) {
        success(res);
    } else if (res.status == 9999) {
        AuthUtil.goLogin(window.location.href);
    } else {
        Toast.show({
            icon: 'fail',
            content: res.msg + "\n" + res.data
        })
        fail && fail(res);
    }
}
export function post(url, param = {}, config = {}) {
    return new Promise((success, fail) => {
        axios.post(url, param).then((res) => {
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }
        }, (err) => {
            Toast.show({
                icon: 'fail',
                content: err.message
            })
        })
    })
}

export function get(url, param = {}, config = {}) {
    return new Promise((success, fail, reject) => {
        axios.get(url, param).then((res) => {
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }

        }, (err) => {
            reject && reject(err);
        })
    })
}