

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Toast, Input } from 'antd-mobile'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const Page = ({
    value,
    minValue = 0,
    maxValue = 999,
    initialValues = 1,
    onChange = () => {
    }
}) => {

    const addValue = () => {
        if (value == null) {
            value = initialValues
        }
        if (value + 1 <= maxValue) {
            onChange(value + 1)
        } else {
            onChange(maxValue)
        }
    }
    const minusValue = () => {
        if (value == null) {
            value = initialValues
        }
        if (value - 1 >= minValue) {
            onChange(value - 1)
        } else {
            onChange(minValue)
        }
    }

    return (
        <div>
            <div className="flex-row flex-row-center number-input">
                <MinusOutlined onClick={minusValue}></MinusOutlined>
                <Input type="number" value={value} onChange={onChange} style={{ width: 60 }} defaultValue={initialValues}></Input>
                <PlusOutlined onClick={addValue} ></PlusOutlined>
            </div>
        </div>
    );
}

export default Page;