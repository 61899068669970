import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, Tabs } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import ImgUtil from 'util/img'
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import { ReactSVG } from 'react-svg';

import './index.css'

function Page() {

    const metaCode = "aiLawyerProtocolOrder";
    const navigate = useNavigate();

    const [param, setParam] = useState({
        // params: [],
        // metaCode: metaCode,
        // orders: [{
        //     attrCode: "showOrder",
        //     order: "desc"
        // }, {
        //     attrCode: "createTime",
        //     order: "desc"
        // }]
    });


    const [robotData, setRobotData] = useState({
        records: []
    });

    useEffect(() => {

        window.document.title = "我的文书";

    }, [])

    return (
        <div className="lawyer-page mine-protocol-page" >
            {/* <NavBar onBack={() => {
                navigate(-1)
            }} >我的文书</NavBar> */}

            <Tabs onChange={(key) => {
                if (key != 'all') {
                    setParam({
                        ...param,
                        status: key
                    })
                } else {
                    setParam({
                        ...param,
                        status: '',
                    })
                }

            }}>
                <Tabs.Tab title='全部' key='all'>
                </Tabs.Tab>
                <Tabs.Tab title='制作中' key='making'>
                </Tabs.Tab>
                <Tabs.Tab title='审核中' key='approvaling'>
                </Tabs.Tab>
                <Tabs.Tab title='已完成' key='finish'>
                </Tabs.Tab>
            </Tabs>
            <ScrollList
                // url={'/api/app/my/meta/data/search'}
                url={'/api/ailawyer/my/protocol/order/search'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(item => (

                        <div className="protocol-item" key={item.code}
                            onClick={() => {
                                navigate("/ailawyer/my/protocol/detail?code=" + item.code);
                            }}>
                            <div className="flex-row t1">
                                <div className="protocol-icon">
                                    <ReactSVG src={item.protocolIcon} />
                                </div>
                                <div className="flex-column flex-1  ml-10">
                                    <div className="font-13 font-bold">{item.lawyerName}</div>
                                    <div className="flex-row mt-4">
                                        <div className="gray-tag mr-5">{item.protocolName}</div>
                                        <div className="gray-tag">{moment(item.createTime).format('YYYY-MM-DD')}</div>
                                    </div>
                                </div>
                                <div className={"item-status status-" + item.status}>
                                    {getMetaLabel('PROTOCOL_ORDER_STATUS', item.status)}
                                </div>
                            </div>

                        </div>
                    ))
                }

            </ScrollList>
        </div>
    );
}
addRoute("/ailawyer/mine/protocol", Page);
export default Page;
