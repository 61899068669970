import React from 'react'
import { NavBar } from 'antd-mobile'
import Url from 'util/url';

const Page = ({
}) => {


    return (
        <div className="no-scroll">
            <NavBar onBack={() => {
                Url.closePage();
            }}>用户协议</NavBar>
            <div className="pd-10-20 scroll-body-page" >
                <b>
                    本协议由你与北京未来有伴科技有限公司（以下简称“未来有伴”或“律伴”）在北京市朝阳区签署。
                </b><br /><br />
                律伴平台，指律伴向您提供产品/服务的网络平台，包括移动应用程序（APP）、PC端网站、H5页面、小程序以及未来可能出现的其他形式；具体以我们实时发布的服务功能界面范围为准等。
                <br /><br />
                律伴产品/服务，指律伴平台经营者（指：未来有伴）通过律伴平台向您提供的律伴产品及/或相关服务，包括各类信息及技术的平台服务、法律咨询、法律文书制作服务等。<br /><br />
                1.特别提示<br /><br />
                1.1前述本协议前，请你务必认真阅读、充分理解本协议各条款，以及本协议项下某项服务的单独协议或特别条款的内容；特别是免除和限制责任的条款、法律责任条款、法律适用和争议解决条款以及其它以加粗加黑和/或加下划线等显示形式提示您注意的重要条款。<br /><br />
                1.2 一旦你勾选本协议的选框，或者点击同意本协议的按扭，或者有实际登录或使用律伴产品/服务等行为的，即表示您已充分阅读、理解、认可和接受本协议的全部内容，自愿接受本协议约束，并与律伴平台达成一致，成为律伴用户。阅读本协议过程中，如你不同意本协议或其中任何条款约定，您应立即停止注册、登录或使用律伴产品/服务。<br /><br />
                1.3如您是未成年人的，请在法定监护人的陪同下阅读和判断是否同意本协议，特别注意未成年人条款。未成年人行使和履行本协议项下的权利和义务视为已获得监护人的认可。<br /><br />
                1.4您同意并理解，律伴根据平台业务的调整、国家法律法规、政策变化等原因，有权不时地调整、变更、终止、增加或减少律伴产品/服务的类型、服务内容、服务规则、服务收费等内容，具体以届时律伴平台展示说明的内容为准；您应在登录或使用（特别是购买）律伴产品/服务前应仔细了解相关产品/服务的服务协议、服务内容及收费等事项。<br /><br />
                1.5 您知晓并理解，律伴作为一个法律服务平台，为您提供的法律咨询、在线法律解决方案、实用工具、法律文书制作、匹配律师等多种产品/服务，由律伴平台的合作律师或律伴法律顾问团队为您提供服务。<br /><br />
                1.6 本协议的各项标题表述仅为概括条款使用，不作为对于具体条款解释的限制、参考。对于条款的解释，应以合同目的为出发点并结合上下文义做出合理解释。<br /><br />
                1.7除非另有说明，本协议适用您注册、登录、访问的任何类型的律伴平台，以及律伴平台向您提供的律伴产品/服务。<br /><br />
                2、本协议的组成<br /><br />
                本协议为律伴平台统一适用的一般性用户服务条款。针对某些特定产品/服务，律伴还将向您提供并与您签署某项产品/服务的单独协议或特别条款，特定产品/服务的单独协议或特别条款是本协议的组成部分，除非另有说明，单独协议或特别条款与本协议不一致的，以单独协议或特别条款的约定为准。<br /><br />
                除此之外，律伴还将根据业务实际情况在律伴平台发布各项产品/服务的单独规则、通知、公告、提示、说明、声明、警示、隐私政策等内容（以下统称“规则”），以便您能更加快速、便捷的了解律伴产品/服务。相关规则是本协议的重要组成部分，与本协议具有同等法律效力。<br /><br />
                3、本协议的变更<br /><br />
                您同意并认可，律伴有权根据律伴平台业务发展需要不时地修订本协议、本协议项下某项产品/服务的单独协议或特别条款、以及律伴平台的各项规则。相关协议、条款或规则修订后，我们将可能以网站公告/通知、弹框、站内信或者更新本协议等方式向您告知。<br /><br />
                如果您不同意修订后的协议、条款或规则，您应立即停止注册、登录或使用律伴产品/服务，但您在修订前已经购买的律伴产品/服务将按原协议/条款/规则继续履行。您同意并认可，如果您在协议/条款/规则修订后继续注册、登录或使用律伴平台/服务，视为您已充分阅读和理解修订的全部内容，并愿意遵守修订后的协议、条款或规则。<br /><br />
                4、用户注册<br /><br />
                4.1为简化注册手续并满足国家法律法规有关网络用户实名制的要求，律伴平台采取实名制手机号码及验证码方式的注册使用方式，手机号码将是您的律伴账户。如您需要注册、登录或使用律伴产品/服务，您应采用自己实名注册的手机号码注册、登录或使用律伴平台/服务。您注册成功后，我们将为您配置账号，该账号之所有权归律伴平台所有，用户在本协议有效期内享有使用权。<br /><br />
                4.2您在注册或使用律伴平台服务时可能需要提供一些必要的信息。您须保证所填写及所提供的资料真实、准确、完整，否则可能无法使用平台服务，或在使用过程中受到限制，甚至影响您是否能够使用平台特定功能。对于因您提供的信息不真实、不准确或不完整导致的责任和损失将由您个人自行承担。<br /><br />
                任何情况下，您不得使用他人实名注册的手机号码、非实名注册的手机号码、或以其他不当、非法方式注册、登录或使用律伴产品/服务，否则您应承担因此造成的一切后果和损失，且律伴有权采取冻结或关闭账户、取消订单、拒绝提供服务等措施。<br /><br />
                平台有权根据您所提供资料的真实性、准确性、完整性以及是否符合平台服务规定的其他条件，决定是否为您提供服务。您在使用平台服务过程中，所提供的信息发生变动，应及时进行变更。<br /><br />
                4.3 您有权使用律伴账户登录或使用律伴产品/服务，您的律伴账户仅限于您本人使用。未经律伴平台同意，您无权授权第三方使用您的律伴账户或通过您的律伴账户登录或使用律伴产品/服务。您同意，如存在上述情况，您应对您账号下的一切行为承担责任，且律伴有权采取冻结或关闭账户、取消订单、拒绝提供服务等措施。<br /><br />
                您知悉并理解，如不同账号绑定或关联同一手机号、同一支付账户、同一身份证信息、同一设备号或相同收货信息，所述不同账号将可能被视为关联账号（即同一用户），在此情况下，我们有权进行合并或强制注销相关账号。<br /><br />
                4.4 如果您需要注销您的账号，您有权通过平台公布的方式、程序进行，我们将在核实您的身份并清理账号资产（例如已充值或已获得的虚拟资产等）及纠纷争议后，为您提供账号注销服务。<br /><br />
                在注销账号之后本协议即终止，我们将停止为您提供任何服务。当然，我们也保留权利在您的账号符合回收的如下情况下，直接回收并注销您的账号并停止服务：⑴未通过实名认证；⑵连续12个月未曾登录、使用平台服务；⑶其它情况。<br /><br />
                4.5由于用户账号关联用户实名信息，未经律伴平台同意，您的律伴账户禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用。如律伴发现或有合理理由认为您存在上述行为或您的账号使用者并非实际注册人，律伴有权在不通知您的情况，暂停或终止向账号提供服务，并有权采取冻结或关闭账户、取消订单等措施。因此造成的一切后果和损失，您应自行承担。<br /><br />
                5.责任限制<br /><br />
                5.1律伴平台将依照法律规定和相关协议约定履行义务，但基于网络服务以及律伴产品/服务的特殊性，你同意并理解，对于下列情形导致的服务中断或不能满足您的要求等情形的，律伴无需承担相应的法律责任：<br /><br />
                A.因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；<br /><br />
                B.因电力供应故障、通讯网络故障、黑客攻击、病毒感染、网络中断、系统不稳定、系统不兼容等网络或技术因素或第三人因素；<br /><br />
                C.因用户关机、用户欠费、用户通过非法途径下载或使用律伴平台、用户泄露（或妥善保管）账户或密码等您自身因素。<br /><br />
                5.2您由于非律伴平台的原因和在任何责任理论项下发生的任何直接、间接、附带、特殊、后果性或惩罚性的损害，你同意并理解，律伴无需承担相应的法律责任。这应包括但不限于任何利润损失（无论是直接还是间接发生）、任何商誉或业务声誉损失、任何数据丢失、替代物品或服务的购买费用或其他无形损失。<br /><br />
                5.3您应自行、谨慎判断服务人员的能力及水准，若您因律伴平台的服务发生任何争议，您可向律伴平台主张，律伴平台可以根据您的需要为您提供调处服务。您同意并理解，在任何时候，律伴平台仅在存在故意或重大过失的情况下才需要向您承担赔偿或其他法律责任，且赔偿金额以其向您实际收取的服务费金额为限，赔偿的范围仅限于您的直接财产损失。<br /><br />
                您同意并理解，律伴平台为您匹配的服务人员所作出的解答，系基于您对事实的单方面描述，任何情况下，因您自身的因素（如您有虚假陈述、重大遗漏、隐瞒重要事实、捏造事实等情形）造成的任何后果及损失，您应自行承担。<br /><br />
                5.4您同意，律伴按法律规定或相关协议约定必须向您承担赔偿责任的，如律伴已为相关产品/服务购买了责任保险，则因该产品/服务给您造成的损失，首先由保险机构进行理赔，您应当配合办理理赔手续，保险理赔金额不足或保险拒绝赔付的部分，由相应服务者按上述规定负责。<br /><br />
                6.用户规范<br /><br />
                6.1您在注册、登录或使用律伴产品/服务时，应严格遵守国家法律法规，不得发布任何法律法规规定的禁止性内容<br /><br />
                6.2除法律法规另有规定外，未经律伴书面许可，您不得使用律伴平台上的任何数据，不得以复制、传播等任何方式使用律伴平台上展示的信息和资料。任何情形下，未经律伴书面许可，您或您不得授权他人以任何方式使用任何装置（包括但不限于机器人软件、蜘蛛软件、爬虫软件、木马病毒等任何自动程序、脚本、软件）干预或试图干预律伴平台的正常运行或平台上的任何交易、活动，不得以任何理由自行或委托他人、协助他人获取律伴平台的服务、内容、数据，不得采取任何将导致律伴平台负荷加重或可能破坏、篡改、删除、影响律伴平台正常运行的行动。<br /><br />
                6.3如您在使用律伴产品/服务时需要下载软件的，对于这些软件，律伴授予您一项个人的、不可转让及非排他性的许可。您仅可为访问使用律伴产品/服务之目的使用这些软件。为了改善用户体验、提高律伴产品/服务的安全性、便捷性等，律伴可能不时的对软件进行更新。律伴建议您及时更新软件，以便正常使用律伴产品/服务。<br /><br />
                律伴为不同终端设备开发不同的软件版本，您应根据实际情况选择下载合适的版本进行安装。您可直接从律伴网站或律伴授权的其他第三方获取软件。如果您从未经律伴授权的第三方获取软件或与软件相同名称的安装程序，律伴无法保证该软件能够正常使用，并对因此给您造成的损失不承担任何责任。<br /><br />
                6.4如律伴发现或有任何合理理由认为您存在或可能存在上述情形或行为的，律伴有权在不向您通知的情况下，直接中断或终止向您提供律伴产品/服务，并对您的账户采取冻结或关闭账户、删除账户信息、取消订单、拒绝提供服务、拒绝退费等措施。因此造成的一切后果及损失，您应自行承担。<br /><br />
                7.隐私政策<br /><br />
                律伴重视用户的个人信息保护，在您注册、登录或使用律伴产品/服务时，您同意律伴按照律伴平台公布的《隐私政策》收集、使用、共享和披露、存储和保护您的个人信息。<br /><br />
                8.知识产权<br /><br />
                8.1 律伴平台（包括但不限于律伴网站、律伴PC端/移动端应用程序、律伴微信小程序等）由律伴开发并运营，律伴对律伴平台对应的软件（网站）及相应的源代码程序、材料及信息等拥有著作权、专利权（如有）、商标权（如有）等全部知识产权；律伴有权对律伴平台进行修改、复制和传播。律伴仅向您提供使用权许可，您及任何人未经律伴允许，不得以任何方式侵害律伴的知识产权。<br /><br />
                8.2律伴平台上由律伴提供、发布或设计的信息及资料（包括但不限于相关声明、商标、标志标识、产品/服务、法律解决方案等）的著作权（如有）、商标权（如有）等知识产权由律伴所有，您以及任何人不得擅自删除、掩盖或更改律伴平台的声明、商标、产品/服务或其他内容，不得以其他任何方式侵害律伴的合法权益，否则律伴将依法追究行为人的法律责任。律伴平台上由您或其他用户提供的信息（或您在使用律伴产品/服务中提供的信息，包括但不限于咨询问题、案件信息、案件材料等）构成作品，相关作品的著作权等知识产权归属于您或相应用户所有；不管该等信息是否构成作品，您同意律伴及关联公司有权在任何时间无地域限制的免费使用上述信息以及再许可第三方使用的权利；在保护个人信息或隐私或其他商业秘密的情况下，律伴有权对相关信息（如咨询问题）进行整理、汇编、改编并以任何方式进行发布、出版、传播，无需向您支付任何费用。<br /><br />
                基于对数据或者信息的合法加工而获得的具有竞争性的数据/信息权益，除法律法规另有规定外，我们享有独立的使用权益而无须获得您的同意。<br /><br />
                8.3您同意并理解，律伴平台向您提供的任何律伴产品/服务，仅限您本人使用，您不应未经律伴平台允许再将相关产品/服务（或服务结果）以任何方式提供给他人使用。如您擅自将相关产品/服务（或服务结果）提供给他人使用，因此给他人造成的一切后果及损失，您应自行承担。<br /><br />
                9.未成年人条款<br /><br />
                9.1律伴原则上不向未成年人提供服务，律伴建议未成年人在注册、登录或使用律伴产品/服务前，在监护人的陪护、指导下充分阅读和理解本协议的全部内容，并取得监护人的同意。<br /><br />
                9.2未成年人购买律伴产品/服务，应由监护人进行操作或在监护人明示同意下操作。未成年人使用（特别是购买）律伴产品/服务的责任应由其监护人承担。未成年人因未取得监护人同意而使用律伴产品/服务造成的一切后果及损失，由其监护人承担。<br /><br />
                10.服务变更、中断及终止<br /><br />
                10.1您同意并理解，基于法律法规的规定、政策变化、相关政府部门命令、协议约定、律伴平台业务变更、经营策略调整、公司运营、公司合并/分立、收购、资产转让、重组等原因，律伴可能对律伴产品/服务内容进行变更，也可能中断、中止或终止服务。<br /><br />
                您根据本协议约定或法律法规规定，或您不愿意继续使用律伴产品/服务的，有权向律伴申请中断、中止或终止服务，或者申请注销律伴账户。<br /><br />
                2.【处理措施】<br /><br />
                中断、中止或终止服务后，律伴可能对您的账户采取冻结、关闭、停止提供服务、注销账户等措施。一旦您的账户被注销后，您将无法继续使用该账户访问或使用律伴产品/服务，您注册、登录或使用律伴产品/服务的产生的信息、资料、数据及记录等也将可能被删除。您同意并理解，律伴在您的账户注销后将不再向您提供与账户有关的服务。但您在账户注销前使用（特别是购买）的服务的单独协议或特别条款另有约定的，按其约定处理。<br /><br />
                11.争议解决<br /><br />
                11.1本协议的生效、履行、解释及争议的解决均适用中华人民共和国（港、澳、台地区除外）的法律。本协议或某项产品/服务的单独协议或特别条款因与国家法律法规相抵触而导致部分无效的，不影响其他部分的效力。您同意，解决争议时，应以您同意的最新版本的《律伴用户服务协议》为准。<br /><br />
                11.2 如因本协议或某项产品/服务的单独协议或特别条款发生争议，各方应尽量协商解决；协商不成时，与上述协议有关的一切争议均可提交北京未来有伴科技有限公司所在地有管辖权的人民法院处理；因争议解决而发生的费用（包括但不限于诉讼、保全/担保、公证/评估/鉴定、律师费用等）将由违约的一方承担。<br /><br />
                11.3对于因本协议争议而引致的任何纠纷，您同意裁判机关按照您在律伴平台注册账号时所提供的信息（包括但不限于邮箱、手机号码、微信、QQ、联系地址等内容）进行法律文书（包括但不限于权利申请类型文件【例如起诉状、反诉状、仲裁申请书、保全申请书等】的正副本、证据、裁判机关及相关评估、鉴定机构出具的文书等）送达，裁判机关可以采取以上一种或多种方式送达，送达时间以最先送达的为准。您确认，上述送达方式适用于整个争议解决阶段；若您所提供的送达信息不确切、或不及时告知变更的，使法律文书无法送达或未及时送达，由此产生的不利后果将由您自行承担。
            </div>

        </div>
    );
}
export default Page;
