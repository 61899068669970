import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Dialog, Tabs, NavBar, ImageUploader, Toast } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate } from 'react-router-dom';
import DictPickerItem from 'component/DictPickerItem'
import DatePickerItem from 'component/DatePickerItem'
import AuthUtil from 'util/auth'

function Page() {


    const navigate = useNavigate();

    const [coin, setCoin] = useState({
    });
    const [account, setAccount] = useState({
        attributes: {}
    });

    const getCoin = () => {
        post('/api/ai/my/coin/get', {
        }).then(res => {
            setCoin(res.data);
        })
    }

    const logout = () => {
        post(module.auth + '/auth/v1.0/logout', {
        }).then(res => {
            Toast.show({
                content: '成功退出',
            })
            AuthUtil.goHome()
        })
    }

    const loadAccountInfo = () => {
        post('/api/ac/my/account/info', {
        }).then(res => {
            setAccount(res.data);
        })
    }
    useEffect(() => {
        loadAccountInfo();
        getCoin();
    }, [])


    return (
        <>
            <NavBar backArrow={null}>我的</NavBar>

            <div className='flex-column-center'>
                <Image
                    src={account.attributes.headimg}
                    style={{ borderRadius: 50 }}
                    fit='cover'
                    width={80}
                    height={80}
                />
                <div className="mt-10">{account.name}</div>
            </div>
            <List header='' className="mt-20">

                <List.Item extra={<span>{coin.totalCoin}币</span>} onClick={() => {
                }}>
                    <div className="flex-row">
                        <Image src={require("./s2.png")} style={{ width: 30, marginRight: 10 }} />剩余i币
                    </div>
                </List.Item>

            </List>
            <div className="pd-20">
                <Button color='primary' onClick={() => {
                    navigate("/ai/coin/buy")
                }} block>立即充值</Button>
                {/* <Button style={{ marginTop: 20 }} color='danger' onClick={() => {
                    logout()
                }} block>退出</Button> */}
            </div>

            <div className="text-center mt-20">
                <div className="font-16 " style={{ marginBottom: 20, color: "#ff0000" }}>添加客服，有机会赠送i币哦！</div>
                <Image src={window.PUBLIC_URL + "/static/images/xhcms/xhkf.jpg"} style={{ width: '70%', margin: "0 auto " }} />
            </div>

        </>
    );
}
addPage("/ai/coin/my", Page);
export default Page;
