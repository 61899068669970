

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Toast, ImageUploader } from 'antd-mobile'

const Page = ({
    value,
    maxCount = 1,
    fileSizeLimit = 1024 * 1024 * 5,
    fileSizeLimitText = '请选择小于 5M 的图片',
    multiple = false,
    onDelete = () => { return true },
    onChange = () => {
    },
}) => {

    const [images, setImages] = useState([]);

    const uploadImg = (file) => {
        var newUploadData = new FormData();
        newUploadData.append("file", file);
        return new Promise((resolve, reject) => {
            const url = '/api/app/resource/v1.0/image/upload';
            axios.post(url, newUploadData).then((res) => {
                return resolve({
                    url: res.data.data
                });
            }, (err) => {
                console.log(err);
                reject && reject(err);
            })
        })
    }

    const beforeUpload = (file) => {
        if (file.size > fileSizeLimit) {
            Toast.show(fileSizeLimitText)
            return null
        }
        return file
    }

    useEffect(() => {
        if (value != null && value != '') {
            if (typeof value == 'string') {
                const newImages = []
                if (value.indexOf(',') > -1) {
                    value.split(',').map((item) => {
                        if (item != '') {
                            newImages.push({
                                url: item
                            })
                        }
                    })
                    setImages(newImages)
                } else {
                    setImages([{
                        url: value
                    }])
                }
            } else if (Array.isArray(value)) {
                setImages(value)
            } else {
                setImages([value])
            }
        } else {
            setImages([])
        }
    }, [value])
    return (
        <>
            <ImageUploader
                value={images}
                onChange={(res) => {
                    var valueString = "";
                    let i = 0;
                    res.map((item) => {
                        i++;
                        if (i == res.length) {
                            valueString = valueString + item.url;
                        } else {
                            valueString = valueString + item.url + ",";
                        }
                    })
                    onChange(valueString)
                }}
                multiple={multiple}
                maxCount={maxCount}
                upload={uploadImg}
                beforeUpload={beforeUpload}
                onDelete={onDelete}
            />
        </>
    );
}

export default Page;