import React, { useState, useEffect, useContext } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import StringTag from 'component/StringTag';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Space, Toast, Image, Button, Tag } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { HeartOutline, HeartFill, SendOutline } from 'antd-mobile-icons'
import moment from 'moment'
import { LoginContext } from 'util/context';
import Url from 'util/url';



function Page() {

    const navigate = useNavigate();
    const [collectId, setCollectId] = useState(null);
    const hasLogin = useContext(LoginContext);
    const [article, setArticle] = useState({});

    useEffect(() => {

        post('/api/app/public/meta/data/get', {
            metaCode: "cmsArticle",
            id: Url.getParam("id")
        }).then(res => {
            setArticle(res.data);
        })
        window.document.title = "通知详情"
    }, [])

    return (
        <>
            {/* <NavBar onBack={() => {
                navigate(-1)
            }}>详情</NavBar> */}
            <div className="pd-10-20">
                <div className="font-16">{article.title}</div>
                <div className="font-12 c-gray pd-tb-15" >
                    {moment(article.createTime).format('YYYY-MM-DD HH:mm')}
                    <span className="ml-10">{article.creatorName}</span>
                </div>
                {/* <div className="font-14 pd-tb-20">详情</div> */}
                <div className="lh-26">
                    <div dangerouslySetInnerHTML={{
                        __html: article.content
                    }}
                    />
                </div>
            </div>
        </>
    );
}
addRoute("/ailawyer/mine/notice/detail", Page);
export default Page;
