import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, Ellipsis } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import AuthUtil from 'util/auth';
import ScrollList from 'component/ScrollList'
import CustomNavbar from './navbar/index'
import WeixinUtil from 'util/weixin';
import './index.css'

function Page() {

    const metaCode = "ailawyerRobot";
    const navigate = useNavigate();

    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        params: [{
            attrCode: "status",
            value: "online",
            operator: "="
        }],
        orders: [{
            attrCode: "useCount",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });

    const gotoChat = (robotCode) => {
        if (AuthUtil.hasLogin()) {
            navigate("/ailawyer/chat/detail?robotCode=" + robotCode);
        } else {
            AuthUtil.goLogin("/ailawyer/chat/detail?robotCode=" + robotCode);
        }
    }

    const [robotData, setRobotData] = useState({
        records: []
    });

    useEffect(() => {
        WeixinUtil.jsConfig();
        WeixinUtil.updateShareData("律伴AI", "AI与入住律师合作制作民间借贷、婚姻文书，更便宜",
            window.APP_CONFIG.h5Domain,
            window.PUBLIC_URL + "/static/images/ailawyer/logo1.png");
        window.document.title = "首页";
    }, [])

    return (
        <div className="lawyer-page lawyer-index-page" >
            {/* <NavBar backArrow={null} >首页</NavBar> */}
            <ScrollList
                url={'/api/app/public/meta/data/search'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(robot => (

                        <div className="robot-item" key={robot.id}
                            onClick={() => {
                                gotoChat(robot.code);
                            }}>
                            <div className="flex-row t1">
                                <Image src={robot.headimg} className="robot-headimg" />
                                <div className="flex-column-left  flex-1">
                                    <div className="font-14">{robot.name}</div>
                                    <div className="flex-row justify-between t1-data ">
                                        <div className="flex-row" >
                                            <div className="t1-tag">使用人数：<span className="s">{robot.useCount}</span></div>
                                            <div className="t1-tag">承接律师：<span className="s">{robot.lawyerCount}</span></div>
                                        </div>
                                        <div className="flex-row ">
                                            <div className="like-icon mr-5" ></div>
                                            <span className="like-title">{robot.likeCount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="t2">
                                <Ellipsis direction='end' rows={2} content={robot.description} />
                            </div>
                            <div className="t3 ">
                                {robot.tags && robot.tags.split(",").map((tag, index) => (
                                    index <= 2 && <div className="gray-tag ml-10" key={tag}>{tag}</div>
                                ))}
                                {/* <div className="flex-row w5">
                                    <div className="like-icon mr-5" ></div>
                                    {robot.likeCount}
                                </div> */}
                                {/* <div className="flex-row">
                                    <div className="comment-icon mr-5" ></div>
                                    {robot.likeCount}
                                </div> */}
                                {/* <div className="share-icon" ></div> */}
                            </div>
                        </div>
                    ))
                }

            </ScrollList>
            <CustomNavbar />
        </div>
    );
}
addRoute("/ailawyer/robot", Page);
export default Page;