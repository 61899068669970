import React, { useState, useEffect, useRef } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import { getMetaLabel } from 'util/meta';
import ShareCanvas from 'component/ShareCanvas';

import axios from "axios";


function Page() {

    const share = useRef(null);


    useEffect(() => {

        // window.wx.updateAppMessageShareData({
        //     title: "test", // 分享标题
        //     desc: "test", // 分享描述
        //     link: "test", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl: "http://web.ailawyer.youbanai.com/static/images/ailawyer/logo-login.png", // 分享图标
        //     success: function () {
        //         // 设置成功
        //         alert("设置成功")
        //     },
        //     fail: function () {
        //         // alert("设置失败")
        //     }
        // })

        // share.current.scale()
        share.current.fillRect(0, 0, 375, 375, "#ffffff")
        share.current.drawImg("https://static-1306521133.cos.ap-beijing.myqcloud.com/bc3869cf28134c5a984ad31107c4460b/images/44a46a5d0f6b4ce39b539449485e251a", 10, 10, 50, 50)
        share.current.drawImg("https://static-1306521133.cos.ap-beijing.myqcloud.com/xcloud/xcloud-mobile/build/static/images/ailawyer/location.png", 70, 28, 15, 15)
        share.current.fillText("北京", 88, 40, "#000000", 12);
        share.current.fillText("本月服务:", 160, 40, "#000000", 12);
        share.current.fillText("20", 215, 41, "#000000", 14);
        share.current.fillText("累计服务:", 240, 40, "#000000", 12);
        share.current.fillText("130", 295, 41, "#000000", 14);
        share.current.fillText("张三律师", 10, 80, "#000000", 12);
        share.current.fillTag("北京高速律师事务所", 90, 62, "#10A37F", "#EAF3F1", 12);
        share.current.wrapFillText("毕业于北京科技大学，毕业于北京科技大学，毕业于北京科技大学，毕业于北京科技大学，毕业于北京科技大学毕业于北京科技大学", 10, 110, "#000000", 12, 340, 20);
        share.current.fillTag("借条", 10, 162, "#3D445B", "#F6F6F6", 12);
        share.current.fillTag("借条", 60, 162, "#3D445B", "#F6F6F6", 12);
        share.current.fillTag("借条", 110, 162, "#3D445B", "#F6F6F6", 12);
        share.current.fillTag("借条", 160, 162, "#3D445B", "#F6F6F6", 12);
        share.current.fillQrcode("https://lawyer.youbanai.com/", 250, 180)
        share.current.transferToImg();
        share.current.show();


    }, [])

    return (
        <div className="lawyer-page">
            <ShareCanvas ref={share} height={300} width={window.innerWidth - 20} />



        </div>
    );
}
addPage("/test/canvas", Page);
export default Page;
