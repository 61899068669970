import React, { useState, useEffect } from 'react'
import { Badge, TabBar } from 'antd-mobile'
import { useNavigate, useLocation } from 'react-router-dom';
import AuthUtil from 'util/auth'
import './index.css'

function Page() {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState();

    useEffect(() => {
        const firstLocation = (location.pathname.match(/\/(\S*)\//) || location.pathname.match(/\/(\S*)/));
        if (firstLocation != null) {
            setActiveKey(firstLocation.input)
        }
    }, [location.pathname])

    const tabs = [
        {
            key: "/ailawyer/robot",
            title: '首页',
            needLogin: false,
            icon: <div className={"navbar-icon home-icon"}></div>,
            badge: Badge.dot,
        },
        {
            key: '/ailawyer/chat',
            title: '聊天',
            needLogin: true,
            icon: <div className={"navbar-icon chat-icon"}></div>,
            badge: '5',
        },
        {
            key: '/ailawyer/lawyer',
            title: '律师',
            needLogin: true,
            icon: <div className={"navbar-icon lawyer-icon"}></div>,
            badge: '99+',
        },
        {
            key: '/ailawyer/mine',
            title: '我的',
            needLogin: true,
            icon: <div className={"navbar-icon mine-icon"}></div>,
        },
    ]

    const checkNeedLogin = (key) => {
        const tab = tabs.find(item => item.key == key);
        if (tab) {
            return tab.needLogin;
        }
        return false;
    }

    return (
        <div className="lawyer-navbar">
            <TabBar onChange={(key) => {
                if (checkNeedLogin(key)) {
                    if (AuthUtil.hasLogin()) {
                        navigate(key);
                        setActiveKey(key);
                    } else {
                        AuthUtil.goLogin(key);
                    }
                } else {
                    navigate(key);
                    setActiveKey(key);
                }
                return;
            }} activeKey={activeKey}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    );
}
export default Page;
