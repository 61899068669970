import React, { useState, useEffect, useRef } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import { Image, NavBar, Button, SpinLoading, Mask, TextArea } from 'antd-mobile'
import { useNavigate, } from 'react-router-dom';
import { HeartOutline, AddCircleOutline, UpOutline, DownOutline, FileOutline } from 'antd-mobile-icons'
import { v4 as uuidv4 } from 'uuid';
import ImgUtil from 'util/img'
import AuthUtil from 'util/auth'
import ImChat from 'util/Im';
import { HeartFilled, VideoCameraFilled } from '@ant-design/icons';
import Url from 'util/url';
import WeixinUtil from 'util/weixin';
import ShareCanvas from 'component/ShareCanvas';

import './index.css'
import { FaRegThumbsUp, FaThumbsUp, FaVoicemail } from 'react-icons/fa';

function Page() {
    const share = useRef(null);
    const textArea = useRef(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [quesionVisible, setQuestionVisible] = useState(false);
    const [toolVisible, setToolVisible] = useState(false);
    const [protocolList, setProtocolList] = useState([]);

    const [chatHistoy, setChatHistoy] = useState({
        records: []
    });
    const [msg, setMsg] = useState('');
    const [robot, setRobot] = useState({
    });
    const [chatCode, setChatCode] = useState(null);
    const [questions, setQuestions] = useState([]);

    const resetChatHistory = (datas) => {
        chatHistoy.records = datas;
        setChatHistoy({ ...chatHistoy });
    }
    const loadHistory = (chatCode) => {
        post('/api/ai/my/chat/history/get', {
            chatCode: chatCode,
        }).then(res => {
            const newRecords = [];
            res.data.forEach((item) => {
                newRecords.push(JSON.parse(item));
            })
            resetChatHistory(newRecords);
        })
    }

    const getRobot = (robotCode) => {
        post('/api/ailawyer/public/robot/get', {
            code: robotCode,
        }).then(res => {
            setRobot(res.data)
            window.document.title = "与" + res.data.name + "沟通中";
        })
    }
    const createChat = (robotCode) => {
        post("/api/ailawyer/public/chat/getOrCreate2", {
            robotCode: robotCode
        }).then(res => {
            initChat(res.data.chatCode)
            if (res.data.isNew) {
                setTimeout(function () {
                    appendRecord({
                        msgId: uuidv4(),
                        msgType: "text",
                        content: res.data.greeting,
                        chatCode: res.data.chatCode,
                        source: "Ai",
                        creatorCode: robotCode,
                        targetCode: window.LOGIN_INFO.code,
                        createTime: new Date().getTime(),
                        headimg: ImgUtil.getRobotHeadimg(robotCode),
                    })
                }, 500);
            }
        })
    }

    const initChat = (chatCode) => {
        setChatCode(chatCode)
        ImChat.onChat(chatCode, (data) => {
            const topic = data.topic;
            const msg = data.msg;
            if (topic.startsWith("token/reply/")) {
                appendRecord(msg)
            }
            if (topic.startsWith("stream/token/reply/")) {
                appendStreamRecord(msg)
            }
        });
        loadHistory(chatCode)
    }

    const appendStreamRecord = (data) => {
        let existMsg = false;
        for (let i = 0; i < chatHistoy.records.length; i++) {
            const item = chatHistoy.records[i];
            if (item.msgId == data.msgId) {
                item.content = item.content + data.content;
                existMsg = true;
                break;
            }
        }
        if (!existMsg) {
            chatHistoy.records.push(data)
        }
        setChatHistoy({ ...chatHistoy });
    }

    const appendRecord = (data) => {
        let existMsg = false;
        for (let i = 0; i < chatHistoy.records.length; i++) {
            const item = chatHistoy.records[i];
            if (item.msgId == data.msgId) {
                existMsg = true;
            }
        }
        if (!existMsg) {
            chatHistoy.records.push(data)
        }
        setChatHistoy({ ...chatHistoy });
        setTimeout(function () {
            const chatContent = document.querySelector('.chat-content');
            chatContent.scrollTop = chatContent.scrollHeight;
        }, 100);
    }

    const confirmSend = (e) => {
        console.log(e)
        if (e.keyCode == 13) {
            sendMsg();
        }
    }


    const goProtocolLawyer = (protocolCode) => {
        navigate(`/ailawyer/chat/protocol?protocolCode=${protocolCode}`)
    }

    const listProtocol = (robotCode) => {
        post("/api/app/public/meta/data/search", {
            metaCode: "ailawyerProtocol",
            params: [{
                attrCode: "robotCode",
                value: robotCode,
                operator: "="
            }],
        }).then(res => {
            setProtocolList(res.data.records)
        })
    }
    const getHeadimg = (msg) => {
        if (msg.headimg != null) {
            return msg.headimg;
        } else if (msg.creatorCode != null) {
            return ImgUtil.getRobotHeadimg(msg.creatorCode)
        } else {
            return "/robot.png"
        }
    }

    const [robotLike, setRobotLike] = useState(false);

    const createRobotLike = () => {
        post("/api/cms/my/like/create", {
            targetType: "LawyerRobot",
            targetCode: robot.code,
        }).then(res => {
            setRobotLike(true)
            robot.likeCount = robot.likeCount + 1;
            setRobot({ ...robot })
        })
    }

    const deleteRobotLike = () => {
        post("/api/cms/my/like/delete", {
            targetType: "LawyerRobot",
            targetCode: robot.code,
        }).then(res => {
            setRobotLike(false)
            robot.likeCount = robot.likeCount - 1;
            setRobot({ ...robot })
        })
    }

    const getRobotLike = (robotCode) => {
        post("/api/cms/my/like/get", {
            targetType: "LawyerRobot",
            targetCode: robotCode,
        }).then(res => {
            setRobotLike(res.data.creatorCode != '')
        })
    }


    const loadRobotQuestion = (robotCode) => {
        post("/api/app/public/meta/data/search", {
            size: 200,
            metaCode: "ailawyerRobotQuestion",
            params: [{
                attrCode: "robotCode",
                value: robotCode,
                operator: "="
            }],
        }).then(res => {
            setQuestions(res.data.records)
        })
    }


    const sendMsg = () => {
        if (!msg) {
            return;
        }
        const msgId = uuidv4();
        const data = {
            "msgId": msgId,
            "msgType": "text",
            "content": msg,
            "chatCode": chatCode,
            "tenantId": window.APP_CONFIG.tenantId,
            "source": "Human",
            "tokenId": AuthUtil.getTokenId(),
            "conversation": 1,
            // "stream": 1,
            "targetCode": robot.code,
            "headimg": window.LOGIN_INFO.attributes.headimg,
            "createTime": new Date().getTime()
        }
        ImChat.client.publish("token/chat", JSON.stringify(data), { qos: 0 }, (error) => {
            if (!error) {
                console.log('发送成功:')
            } else {
                console.log('发送失败')
            }
        })
        appendRecord(data)
        setMsg('');
        const element = textArea.current.nativeElement;
        element.style.height = 'auto';
    }

    const autoFixHeight = () => {
        const element = textArea.current.nativeElement;
        element.style.height = 'auto';
        //如果高度不够，再重新设置
        if (element.scrollHeight >= element.offsetHeight) {
            element.style.height = element.scrollHeight + 'px'
        }
    }

    const shareImg = async () => {
        var startX = 30;
        var startY = 20;
        var headWidth = 60;
        var headHeight = 60;

        setLoading(true);
        share.current.fillRoundRect(0, 0, share.current.getSourceWidth(), share.current.getSourceHeight(), 10, "#D9E8E4")
        share.current.fillRoundRect(15, 39, share.current.getSourceWidth() - 30, share.current.getSourceHeight() - 78, 10, "#ffffff")
        await share.current.drawRoundImg(robot.headimg, startX, startY, headWidth, headHeight, 30)
        // await share.current.drawImg("https://static-1306521133.cos.ap-beijing.myqcloud.com/xcloud/xcloud-mobile/build/static/images/ailawyer/like.png", startX + headWidth + 112, 53, 15, 15)
        // share.current.fillText(robot.likeCount, startX + headWidth + 132, 65, "#000000", 12);
        share.current.fillText(robot.name, startX, 120, "#000000", 18);
        share.current.wrapFillText(robot.description, startX, 150, "#000000", 13, 300, 22, 3);
        share.current.fillText("使用人数:", startX, 240, "#000000", 14);
        share.current.fillText(robot.useCount, startX + 70, 241, "#000000", 16);
        share.current.fillText("承接律师:", startX + 100, 240, "#000000", 14);
        share.current.fillText(robot.lawyerCount, startX + 165, 241, "#000000", 16);
        share.current.fillText("@律伴AI", startX, 305, "#1A1A1A ", 16);
        share.current.fillText("长按识别二维码", startX, 335, "#626262", 12);
        share.current.fillText("获得专业AI律师助理", 120, 335, "#626262", 12);
        await share.current.fillQrcode(window.location.href, 250, 290, 80, 80)
        share.current.transferToImg();
        share.current.show();
        setLoading(false);
    }
    useEffect(() => {
        const robotCode = Url.getParam("robotCode");
        const chatCode = Url.getParam("chatCode");
        if (chatCode) {
            initChat(chatCode)
            post('/api/ai/my/chat/get', {
                chatCode: chatCode,
            }).then(res => {
                getRobot(res.data.robotCode)
                listProtocol(res.data.robotCode)
                getRobotLike(res.data.robotCode)
                loadRobotQuestion(res.data.robotCode)
            })
        } else if (robotCode) {
            getRobot(robotCode)
            listProtocol(robotCode)
            createChat(robotCode)
            getRobotLike(robotCode)
            loadRobotQuestion(robotCode)
        }
        WeixinUtil.jsConfig();

    }, [])


    // useEffect(() => {
    //     setTimeout(() => {
    //         const top = document.getElementsByTagName('html')[0].offsetTop
    //         window.scroll(0, -1 * top)
    //     }, 100);
    // }, [msg])




    return (
        <div className="lawyer-page lawyer-chat-detail-page" >
            {/* <NavBar onBack={() => { navigate(-1) }}>{robot.name}</NavBar> */}
            <div className="robot-item" >
                <div className="flex-row t1" onClick={() => {
                }}>
                    <Image src={robot.headimg} className="robot-headimg" />
                    <div className="flex-column-left  flex-1">
                        <div>{robot.name}</div>
                        <div className="flex-row justify-between t1-data">
                            <div className="flex-row" >
                                <div className="t1-tag">使用人数：<span className="s">{robot.useCount}</span></div>
                                <div className="t1-tag">承接律师：<span className="s">{robot.lawyerCount}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="t3 flex-row">
                    <div className="share-icon ml-40" onClick={() => {
                        shareImg()
                    }} ></div>
                    {robotLike && <div className="flex-row   color-green" onClick={() => {
                        deleteRobotLike()
                    }}>
                        <FaRegThumbsUp className="font-15 mr-5" />
                        {robot.likeCount}
                    </div>}
                    {!robotLike && <div className="flex-row   " onClick={() => {
                        createRobotLike()
                    }}>
                        <FaRegThumbsUp className="font-15  mr-5" />
                        {robot.likeCount}
                    </div>}
                    {/* <div className="flex-row">
                        <div className="comment-icon mr-5" ></div>
                        {robot.likeCount}
                    </div> */}

                </div>

            </div>

            <div className='chat-content'>
                {chatHistoy.records.map((v) =>
                    <div
                        className={v.source != "Human" ? 'chat-msg-item flex-row-left' : 'chat-msg-item  flex-row-reverse-right'}
                        key={v.msgId}>
                        <div >
                            <Image src={getHeadimg(v)} className="chat-msg-item-headimg" />
                        </div>
                        <div className='chat-msg' dangerouslySetInnerHTML={{
                            __html: v.content
                        }}>
                        </div>
                    </div>
                )}
                <div className="chat-send-box" >
                    <div className="flex-row">
                        <div className="chat-send-input flex-row">
                            <TextArea value={msg}
                                ref={textArea}
                                rows={1}
                                placeholder='请输入要咨询的内容'
                                onFocus={() => {
                                    setToolVisible(false)
                                }}
                                onChange={(e) => {
                                    setToolVisible(false)
                                    setMsg(e)
                                    autoFixHeight();
                                }} onKeyDown={confirmSend} />

                        </div>
                        <UpOutline className="font-25 mr-10" onClick={() => {
                            setQuestionVisible(true)
                            setToolVisible(false)
                        }} />
                        {msg != '' && <Button className="btn-send" type="primary" onClick={() => {
                            sendMsg();
                        }}>发送</Button>}
                        {msg == '' && <AddCircleOutline className="font-25 mr-10"
                            onClick={() => {
                                if (toolVisible) {
                                    setToolVisible(false)
                                } else {
                                    setToolVisible(true)
                                    setQuestionVisible(false)
                                }
                            }} />}
                    </div>

                    {toolVisible && <div className="tool-box" onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        {protocolList.map((v) => {
                            return <div className="tool-item" key={v.code}>
                                <div className="tool-icon">
                                    {v.icon != '' && <img src={v.icon} className="tool-icon-img" onClick={() => {
                                        goProtocolLawyer(v.code)
                                    }} />}
                                    {v.icon == '' && <FileOutline className='font-26 mb-5' onClick={() => {
                                        goProtocolLawyer(v.code)
                                    }} />}
                                </div>
                                {v.name}
                            </div>
                        })
                        }
                    </div>}
                </div>


            </div>


            {quesionVisible && <div className="question-bg" onClick={() => {
                setQuestionVisible(false)
            }}>
                <div className="question-box">
                    <div className="question-title">常见问题
                        <DownOutline className="icon-down font-30" onClick={() => {
                            setQuestionVisible(false)
                        }} />
                    </div>
                    <div className="question-list">
                        {questions.map((v) => (
                            <div key={v.id} className="question-item" onClick={() => {
                                setMsg(v.question);
                                setTimeout(() => {
                                    autoFixHeight();
                                }, 50)
                                setQuestionVisible(false)
                            }}><span>{v.question}</span></div>
                        ))}
                    </div>
                </div>
            </div>}

            <Mask visible={loading} className="flex-column" style={{ textAlign: 'center', color: "#ffffff" }}>
                <SpinLoading style={{ '--size': '48px', margin: '20rem auto 0' }} color={"#ffffff"} />
                <div style={{ margin: "1rem 0 0  0 " }}>加载中...</div>
            </Mask>
            <ShareCanvas ref={share} height={440} width={window.innerWidth - 20} />
        </div>
    );
}
addRoute("/ailawyer/chat/detail", Page);
export default Page;
