import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Dialog, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import CaptchaButton from 'component/CaptchaButton';



function Page() {



    const metaCode = "paifuProject";

    const [pageForm] = Form.useForm()


    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })


    const submitStep = () => {
        pageForm.validateFields().then((values) => {
            post(module.ac + '/my/account/phone/update', values).then(res => {
                Toast.show({
                    content: '更新成功',
                })
                navigate(-1);
            })
        })
    }

    useEffect(() => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            pageForm.setFieldsValue({
                phone: res.data.phone
            });
        })
    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改手机号</NavBar>
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='phone' label="手机号" rules={[{ required: true, message: '请输入手机号' }]}>
                        <Input placeholder='请输入手机号' />
                    </Form.Item>
                    <Form.Item name='smsCode' label="短信验证码"
                        rules={[{ required: true, message: '请输入短信验证码' }]}
                        extra={<CaptchaButton fill='none' size="small" business="phoneUpdate" getPhone={() => {
                            return pageForm.validateFields(['phone']).then(res => {
                                return res.phone;
                            }, err => {
                                return null;
                            })
                        }}></CaptchaButton>} >
                        <Input placeholder='请输入验证码' />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/personal/edit/phone", Page);
export default Page;
