import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, NavBar, Ellipsis, Tabs, Swiper, Modal, Toast } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import ImgUtil from 'util/img'
import ScrollList from 'component/ScrollList'
import WeixinUtil from 'util/weixin';
import Url from 'util/url';
import LawyerBase from './base'
import LawyerProtocol from './protocol'
import LawyerNews from './news'
import moment from 'moment';
import './index.css'
import LaywerItem from 'pages/lawyer/component/lawyer-item/index';


function Page() {

    const navigate = useNavigate();
    const [lawyer, setLawyer] = useState({});
    const [account, setAccount] = useState({
        attributes: {}
    });
    const defaultKey = window.sessionStorage.getItem("lawyer-mine-lawyer-tab") || "base";
    const [activeKey, setActiveKey] = useState(Url.getParam("key") || defaultKey);

    const loadLawyerInfo = () => {
        post('/api/ailawyer/my/lawyer/info', {
        }).then(res => {
            setLawyer(res.data)
        })
    }

    useEffect(() => {
        WeixinUtil.jsConfig();
        post('/api/ac/my/account/info', {
        }).then(res => {
            setAccount(res.data);
        })
        loadLawyerInfo();
    }, [])

    return (
        <div className="lawyer-page lawyer-mine-lawyer-page ">


            <LaywerItem lawyer={lawyer} showLastMonthUse={false}
                showTotalUse={false} showEndTime={true} showLike={false} />
            {/* <div className="lawyer-item" key={lawyer.creatorCode}>

                <div className="t1">
                    <div className="flex-row s1">
                        <img src={account.attributes.headimg} className="headimg" />
                        <div className="lawyer-name">{lawyer.name}</div>
                        <div className="company-name">{lawyer.companyName}</div>
                    </div>
                    <div className='flex-row flex-justify-between'>
                        <div className="flex-row">
                            <div className="location-icon"></div>
                            <div className="font-12 ml-5">北京</div>
                        </div>
                        <div className="green-tag" onClick={() => {
                            navigate("/ailawyer/mine/lawyer/account")
                        }}>
                            有效期：{moment(lawyer.endTime).format('YYYY-MM-DD')}
                        </div>
                    </div>

                </div>

            </div> */}

            <Tabs activeKey={activeKey} onChange={(key) => {
                setActiveKey(key);
                window.sessionStorage.setItem("lawyer-mine-lawyer-tab", key);
            }}>

                <Tabs.Tab title='基本信息' key='base'>
                    <LawyerBase />
                </Tabs.Tab>
                <Tabs.Tab title='相册动态' key='news' >
                    <LawyerNews />
                </Tabs.Tab>
                <Tabs.Tab title='文书服务' key='protocol'>
                    <LawyerProtocol />
                </Tabs.Tab>

            </Tabs>


        </div>
    );
}
addPage("/ailawyer/mine/lawyer/index", Page);
export default Page;
