import React, { useState, useEffect, useRef } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, NavBar, Ellipsis, Tabs, Swiper, Modal, Toast, Empty, Button } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import ImgUtil from 'util/img'
import ScrollList from 'component/ScrollList'
import WeixinUtil from 'util/weixin';
import Url from 'util/url';
import moment from 'moment';
import { format } from 'util/date'
import ShareCanvas from 'component/ShareCanvas';
import LaywerItem from 'pages/lawyer/component/lawyer-item/index';
import { ReactSVG } from 'react-svg';

import './index.css'

function Page() {

    const share = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [lawyer, setLawyer] = useState({});
    // const [lawyerImages, setLawyerImages] = useState([]);
    // const [lawyerContent, setLawyerContent] = useState("");

    const defaultKey = window.sessionStorage.getItem("lawyer-detail-tab") || "protocol";
    const [activeKey, setActiveKey] = useState(Url.getParam("key") || defaultKey);

    const [formVisible, setFormVisible] = useState(false)
    const [protocolLawyer, setProtocolLawyer] = useState({})


    const [activityParam, setActivityParam] = useState({
        metaCode: "cmsArticle",
        params: [{
            attrCode: "channelCode",
            value: "lawyerActivity",
            operator: "="
        }, {
            attrCode: "tags",
            value: Url.getParam("code"),
            operator: "="
        }],
        orders: [{
            attrCode: "publishTime",
            order: "desc"
        }]
    });

    const [protocolParam, setProtolParam] = useState({
        params: [],
        metaCode: "ailawyerProtocolLawyer",
        params: [{
            attrCode: "lawyerCode",
            value: Url.getParam("code"),
            operator: "="
        }],
        lawyerCode: Url.getParam("code"),
        orders: []
    });

    const [honorData, setHonorData] = useState({
        records: []
    });

    const [protocolData, setProtolData] = useState({
        records: []
    });

    const [activityData, setActivityData] = useState({
        records: []
    });

    const loadLawyerInfo = () => {
        post('/api/ailawyer/public/lawyer/get', {
            code: Url.getParam("code")
        }).then(res => {
            setLawyer(res.data)
        })

        // post('/api/ac/public/account/attr/get', {
        //     metaCode: "lawyerImages",
        //     accountCode: Url.getParam("code")
        // }).then(res => {
        //     setLawyerImages(res.data.split(","));
        // })

        post('/api/app/public/meta/data/search', {
            metaCode: "cmsArticle",
            params: [{
                attrCode: "channelCode",
                value: "lawyerHonor",
                operator: "="
            }, {
                attrCode: "tags",
                value: Url.getParam("code"),
                operator: "="
            }],
            orders: []
        }).then(res => {
            setHonorData(res.data);
        });
    }

    const createPayment = (orderCode) => {
        WeixinUtil.goPay(orderCode, function (orderCode) {
            Toast.show({
                content: '支付完成,3秒后自动自动开始协议问答',
            })
            setTimeout(() => {
                navigate(`/ailawyer/my/protocol/detail?code=${orderCode}`)
            }, 3000)
        })
    }

    const createProtocolOrder = (protocolLawyer) => {
        setProtocolLawyer(protocolLawyer)
        setFormVisible(true);
        return;
    }


    useEffect(() => {
        loadLawyerInfo();
        WeixinUtil.jsConfig();
        window.document.title = "律师详情"
    }, [])

    return (
        <div className="lawyer-page lawyer-detail-page ">
            {/* <NavBar onBack={() => { navigate("/ailawyer/lawyer") }}>律师详情</NavBar> */}

            <LaywerItem lawyer={lawyer} />

            <Tabs activeKey={activeKey} onChange={(key) => {
                setActiveKey(key);
                window.sessionStorage.setItem("lawyer-detail-tab", key);
            }}>

                <Tabs.Tab title='基本信息' key='base'>
                    <List header=''>

                        <List.Item extra={<span>{lawyer.name}</span>} >
                            姓名
                        </List.Item>

                        {lawyer.school != '' && <List.Item extra={<span>{lawyer.school}</span>} >
                            毕业院校
                        </List.Item>}

                        {lawyer.education != '' && <List.Item extra={<span>{lawyer.education}</span>} >
                            学历
                        </List.Item>}

                        <List.Item extra={<span>{lawyer.companyName}</span>} >
                            执业单位
                        </List.Item>

                        <List.Item extra={<span>{lawyer.certificateCode}</span>} >
                            执业证号
                        </List.Item>

                        <List.Item extra={<span>已认证</span>} >
                            执业证
                        </List.Item>
                        <List.Item extra={<span>已认证</span>} >
                            身份证
                        </List.Item>

                        <List.Item extra={<span>{lawyer.regionName}</span>} >
                            地区
                        </List.Item>

                    </List>
                    <div className="intro">
                        <div className="title">简介</div>
                        <div className="font-13" dangerouslySetInnerHTML={{
                            __html: lawyer.remark
                        }}>
                        </div>
                    </div>

                </Tabs.Tab>
                <Tabs.Tab title='相册动态' key='news' >

                    <div className="laywer-mine-laywer-account-news">
                        <div className="image-wrapper">
                            <Swiper slideSize={50}

                                indicator={(total, current) => (
                                    <div className="customIndicator">
                                        {`${current + 1} / ${total}`}
                                    </div>
                                )}
                                trackOffset={0} stuckAtBoundary={false}>{
                                    honorData.records.map((item, index) => {
                                        return <Swiper.Item key={item.code} className='image-item'>
                                            <img src={item.titleImg}></img>
                                            <div className='image-title'>{item.title}</div>
                                        </Swiper.Item>
                                    })
                                }</Swiper>

                            {honorData.records.length == 0 && <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='律师未上传风采照片'
                            />}
                        </div>

                        <div className="news-title">最新动态</div>


                        <ScrollList className="mt-20 news-wrapper"
                            url={'/api/app/public/meta/data/search'}
                            param={activityParam}
                            setParam={setActivityParam}
                            data={activityData}
                            setData={setActivityData}
                        >
                            {
                                activityData.records.map(news => (

                                    <List.Item
                                        key={news.id}
                                    >
                                        <div className="news-item  " onClick={() => {
                                            navigate("/ailawyer/lawyer/detail/news?id=" + news.id);
                                        }}>
                                            <div className="font-14 news-item-time"  >
                                                <div className="circle"></div>
                                                <div className="line"></div>
                                                {moment(news.publishTime).format('YYYY-MM-DD')}
                                            </div>
                                            <Ellipsis direction='end' rows={2} content={news.title} className="news-item-title" />
                                        </div>
                                    </List.Item>
                                ))
                            }
                            {activityData.records.length == 0 && <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='律师未填写任何动态'
                            />}
                        </ScrollList>


                    </div>

                </Tabs.Tab>
                <Tabs.Tab title='文书服务' key='protocol'>

                    <ScrollList
                        // url={'/api/app/public/meta/data/search'}
                        url={'/api/ailawyer/public/lawyer/protocol/search'}
                        param={protocolParam}
                        setParam={setProtolParam}
                        data={protocolData}
                        setData={setProtolData}
                    >
                        {
                            protocolData.records.map(item => (

                                <div className="protocol-item" key={item.protocolCode}  >
                                    <div className="flex-row t1 justify-between">
                                        <div className="flex-row">
                                            <div className="protocol-icon">
                                                <ReactSVG src={item.protocolIcon} />
                                            </div>
                                            <div className="font-15 font-bold ml-10">{item.protocolName}</div>
                                        </div>
                                        {/* <Image src={item.icon} className="robot-headimg" /> */}

                                        <div className="flex-row font-12">
                                            <div>本月人次：<span className="font-13 font-bold">{item.lastMonthUse}</span></div>
                                            <div className="ml-10">总人次：<span className="font-13 font-bold">{item.totalUse}</span></div>
                                        </div>
                                    </div>
                                    {/* <div className="t2 flex-row justify-between font-12 mt-10">
                                        <div className="flex-row">
                                            <div className="like-icon mr-5" ></div>
                                            {item.likeCount}
                                        </div>
                                        <div className="flex-row">
                                            <div className="comment-icon mr-5" ></div>
                                            {item.likeCount}
                                        </div>
                                        <div className="share-icon" ></div>
                                        <div className="gray-tag">{format(item.createTime, "yyyy-MM-DD")}</div>
                                    </div> */}
                                    <div className="t3 flex-row justify-between  mt-10">
                                        <div className="price-wraper">￥<span className="price">{item.unitPrice}</span></div>
                                        <button className="create-btn" onClick={() => {
                                            createProtocolOrder(item)
                                        }}>制作</button>
                                    </div>
                                </div>
                            ))
                        }

                        {protocolData.records.length == 0 && <Empty
                            style={{ padding: '64px 0' }}
                            imageStyle={{ width: 128 }}
                            description='律师未开通任何文书'
                        />}

                    </ScrollList>
                </Tabs.Tab>

            </Tabs>



            <Modal
                visible={formVisible}
                closeOnAction
                onClose={() => {
                    setFormVisible(false)
                }}
                content={<>
                    <div style={{ padding: 20, color: "#3D445B" }}>
                        <div>{protocolLawyer.remark}</div>
                        <div style={{ marginTop: 20 }}>
                            制作律师服务费:<span style={{ color: 'red' }}>{protocolLawyer.unitPrice}</span>元/份
                        </div>
                    </div>
                    <div className="flex-row mt-20" >
                        <Button color='default' block onClick={() => {
                            setFormVisible(false)
                        }}>取消</Button>
                        <Button color='success' block onClick={() => {
                            post('/api/ailawyer/public/protocol/order/create', {
                                protocolCode: protocolLawyer.protocolCode,
                                lawyerCode: Url.getParam("code")
                            }).then(res => {
                                createPayment(res.data.code)
                            })
                        }}>提交</Button>

                    </div>
                </>}
            >
            </Modal>
            <ShareCanvas ref={share} height={440} width={window.innerWidth - 20} />

        </div >
    );
}
addPage("/ailawyer/lawyer/detail", Page);
export default Page;
