import { Routes, Route, useLocation } from 'react-router-dom';

global.allRoutes = global.allRoutes || {};
global.allPages = global.allPages || {};
export function addRoute(path, element) {
    global.allRoutes = global.allRoutes || {};
    global.allRoutes[path] = element;
}
export function addPage(path, element) {
    global.allPages = global.allPages || {};
    global.allPages[path] = element;
}



const DynamicRoute = ({
    path
}) => {
    global.allRoutes = global.allRoutes || {};
    if (global.allRoutes[path]) {
        const Element = global.allRoutes[path];
        return <Element />
    } else {
        return (<div>路由不存在</div>);
    }
}

const DynamicPage = ({
    path
}) => {
    global.allPages = global.allPages || {};
    if (global.allPages[path]) {
        const Element = global.allPages[path];
        return <Element />
    } else {
        return (<div>页面不存在</div>);
    }
}

const DynamicRoutes = (props) => {
    return (<Routes>
        {
            Object.keys(global.allRoutes).map((key) =>
                (<Route path={key} element={<DynamicRoute path={key} />} key={key}> </Route>)
            )
        }
    </Routes>
    );
}


export const DynamicPages = (props) => {
    return (<Routes>
        {
            Object.keys(global.allPages).map((key) =>
                (<Route path={key} element={<DynamicPage path={key} />} key={key}> </Route>)
            )
        }
    </Routes>
    );
}


export default DynamicRoutes;