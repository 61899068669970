import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import AuthUtil from 'util/auth.js';
import { ResultPage, Toast } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import WeixinUtil from 'util/weixin';
import Url from 'util/url';




function Page() {

    const navigate = useNavigate();

    useEffect(() => {
        if (!AuthUtil.isInWeixin()) {
            Toast.show({
                content: '请在微信中打开并支付',
            })
        } else {
            const orderCode = Url.getParam("orderCode");
            if (orderCode) {
                WeixinUtil.submitPayment(orderCode, function () {
                    Toast.show({
                        content: '支付完成,5秒后自动关闭页面',
                    })
                    setTimeout(() => {
                        window.close()
                    }, 5000)
                })
            } else {
                Toast.show({
                    content: '订单号不存在',
                })
            }
            WeixinUtil.jsConfig();
        }
    }, [])

    return (
        <ResultPage
            status='waiting'
            title='支付中'
            description={'请在微信中完成支付'}
            secondaryButtonText='返回首页'
            onSecondaryButtonClick={() => {
                navigate("/")
            }}
        >
        </ResultPage>
    );
}
addRoute("/ec/payment/weixin", Page);
export default Page;
