import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, TextArea, Button, Form, Toast, NavBar, Modal, Tag, Popup, Input } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import { format } from 'util/date'
import { SendOutline, HeartOutline, BankcardOutline, CalendarOutline } from 'antd-mobile-icons'
import './index.css'
import DictPicker from 'component/DictPicker'
import { ReactSVG } from 'react-svg';

function Page() {

    const metaCode = "ailawyerRobot";
    const navigate = useNavigate();

    const [formVisible, setFormVisible] = useState(false)

    const [protocolData, setProtocolData] = useState({});

    const [pageForm] = Form.useForm()
    const [robotData, setRobotData] = useState({
        records: []
    });


    const submitOpenLawyerProtocol = () => {
        pageForm.validateFields().then((data) => {
            post('/api/ailawyer/my/protocol/lawyer/open', {
                ...data,
                protocolCode: protocolData.protocolCode
            }).then(res => {
                setParam({ ...param });
                Toast.show({
                    content: '开通成功',
                })
                setFormVisible(false);
                // Modal.clear();
            })
        })
    }
    const openLawyerProtocol = (protocol) => {
        pageForm.setFieldsValue({
            isPublic: protocol.isPublic,
            remark: protocol.remark || protocol.protocolRemark,
            unitPrice: protocol.unitPrice || protocol.protocolUnitPrice
        })
        setProtocolData(protocol);
        setFormVisible(true);
    }

    const closeLawyerProtocol = (protocol) => {
        Modal.confirm({
            title: '操作提示',
            onConfirm: () => {
                const protocolCode = protocol.protocolCode;
                post('/api/ailawyer/my/protocol/lawyer/close', {
                    protocolCode: protocolCode
                }).then(res => {
                    setParam({ ...param });
                    Toast.show({
                        content: '关闭成功',
                    })
                })
            },
            content: '确认关闭该协议吗?',
        })

    }
    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        params: [{
            attrCode: "status",
            value: "online",
            operator: "="
        }],
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });

    return (
        <div className="laywer-protocol">
            {/* <NavBar onBack={() => { navigate(-1) }}>律师服务</NavBar> */}

            <ScrollList
                url={'/api/ailawyer/my/protocol/lawyer/search/all'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(robot => (

                        <div className="protocol-item" key={robot.protocolCode}  >
                            <div className="flex-row t1 justify-between">
                                <div className="flex-row">
                                    <div className="protocol-icon">
                                        <ReactSVG src={robot.protocolIcon} />
                                    </div>
                                    <div className="ml-10">{robot.protocolName}</div>
                                </div>
                                <div className="flex-row font-12">
                                    <div>本月人次：<span className="font-13">{robot.lastMonthUse}</span></div>
                                    <div className="ml-10">总人次：<span className="font-13">{robot.totalUse}</span></div>
                                </div>
                            </div>
                            {/* <div className="t2 flex-row justify-between font-12 mt-10">
                                <div className="flex-row">
                                    <div className="like-icon mr-5" ></div>
                                    {robot.likeCount}
                                </div>
                                <div className="flex-row">
                                    <div className="comment-icon mr-5" ></div>
                                    {robot.likeCount}
                                </div>
                                <div className="share-icon" ></div>
                                <div className="gray-tag">{format(robot.createTime, "yyyy-MM-DD")}</div>
                            </div> */}
                            <div className="t3 flex-row justify-between  mt-10">
                                <div className="price-wraper">￥<span className="price">{robot.unitPrice}</span></div>
                                {(robot.status == '' || robot.status == 'close') && <button className="status-close" onClick={() => {
                                    openLawyerProtocol(robot)
                                }}>未启用</button>}
                                {robot.status == 'normal' && <button className="status-open" onClick={() => {
                                    closeLawyerProtocol(robot)
                                }}>已启用</button>}
                            </div>
                        </div>
                    ))
                }

            </ScrollList >

            <Modal
                visible={formVisible}
                closeOnAction
                onClose={() => {
                    setFormVisible(false)
                }}
                content={<>
                    <Form
                        form={pageForm}
                    >
                        <Form.Item name='unitPrice' label="价格"
                            rules={[{ required: true, message: '请填写价格' }]}>
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item name='isPublic' label="是否公开"
                            rules={[{ required: true, message: '请选择是否公开' }]}>
                            <DictPicker dictCode="TRUE_FALSE"  >
                            </DictPicker>
                        </Form.Item>
                        <Form.Item name='remark' label="说明"
                            rules={[{ required: true, message: '请填写说明' }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                    </Form>
                    <div className="flex-row" >
                        <Button color='default' block onClick={() => {
                            setFormVisible(false)
                        }}>取消</Button>
                        <Button color='success' block onClick={() => {
                            submitOpenLawyerProtocol()
                        }}>提交</Button>

                    </div>
                </>}
            >
            </Modal>

        </div >
    );
}
export default Page;
