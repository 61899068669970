import './App.css';
import './pages/index';
import DynamicRoutes from 'component/DynamicRotes';
import React from 'react';
import { LoginContext } from 'util/context';
import { useState, useEffect } from 'react'
import { post } from 'util/http';
import module from 'util/module';

function App() {

  const [hasLogin, setHasLogin] = useState(false);

  useEffect(() => {
    // post(module.auth + "/auth/v1.0/check/login", {
    // }).then(res => {
    //   setHasLogin(res.data);
    // })


    // window.wx.config({
    //   debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //   appId: 'wx716ea4e6b6bf43e0', // 必填，公众号的唯一标识
    //   timestamp: 11, // 必填，生成签名的时间戳
    //   nonceStr: '', // 必填，生成签名的随机串
    //   signature: '',// 必填，签名
    //   jsApiList: [] // 必填，需要使用的 JS 接口列表
    // });

  }, []);

  return (
    <>
      <LoginContext.Provider value={hasLogin}>
        <DynamicRoutes />
      </LoginContext.Provider>
    </>

  );
}

export default App;
