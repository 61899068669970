import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Toast, Button, Radio, Form, Input, Grid } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import CaptchaButton from 'component/CaptchaButton';
import Privacy from './privacy'
import Aggrement from './agreement';
import Url from 'util/url';
import AuthUtil from "util/auth";
import './index.css'


function Page() {

    const [pageForm] = Form.useForm()
    const [agreementChecked, setAgreementChecked] = useState(false);
    const navigate = useNavigate();

    const submitRegister = () => {
        pageForm.validateFields().then((values) => {
            if (!agreementChecked) {
                Toast.show("请阅读并同意用户协议！");
                return;
            } else {
                post(module.auth + "/auth/v1.0/register/by-phone", values)
                    .then(res => {
                        Toast.show({
                            content: '注册成功',
                        })
                        AuthUtil.goLogin();
                    })
            }

        })
    }

    useEffect(() => {
        window.document.title = "注册";
    }, [])
    return (
        <div className="lawyer-page lawyer-login-page">
            {/* <NavBar
                back="首页"
                backArrow={false}
                onBack={() => { navigate(window.APP_CONFIG.defaultPath) }}>注册</NavBar> */}
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <>
                        <Button block type='success' color='success' size='middle' onClick={submitRegister} style={{ marginTop: 10 }}>
                            绑定注册
                        </Button>
                        <div className="aggrement-privacy">
                            <Radio checked={agreementChecked} onChange={(v) => { setAgreementChecked(v) }}>已阅读及同意
                            </Radio>
                            <a className="s2" onClick={() => {
                                Url.openPage(<Aggrement></Aggrement>);
                            }}>《律伴用户协议》</a>&nbsp;
                            <a className="s2" onClick={() => {
                                Url.openPage(<Privacy></Privacy>);
                            }}>《隐私政策》</a>
                        </div>

                    </>
                }
            >
                <div className='flex-column flex-align-center logo-bg' >
                    <img src={window.PUBLIC_URL + '/static/images/ailawyer/logo-login.png'} style={{ width: 145, height: 32 }}></img>
                    <div className="font-26">有伴律师</div>
                </div>

                <div >
                    <Form.Item name='name' rules={[{ required: true, message: '请输入姓名' }]}>
                        <Input placeholder='请输入姓名' />
                    </Form.Item>
                    <Form.Item name='phone'
                        style={{ marginTop: 10 }}
                        rules={[
                            { required: true, message: '请输入手机号' },

                            {
                                validator: (_, value) => {
                                    return !(/^1[3-9]\d{9}$/).test(value) ? Promise.reject('手机号格式不正确') : Promise.resolve();
                                }
                            },
                        ]}>
                        <Input placeholder='请输入手机号' type="number" />
                    </Form.Item>
                    <Form.Item name='smsCode'
                        style={{ marginTop: 10 }}
                        rules={[{ required: true, message: '请输入短信验证码' }]}
                        extra={<CaptchaButton fill='none' size="small" business="register" getPhone={() => {
                            return pageForm.validateFields(['phone']).then(res => {
                                return res.phone;
                            }, err => {
                                return null;
                            })
                        }}></CaptchaButton>} >
                        <Input placeholder='请输入验证码' />
                    </Form.Item>
                    <Form.Item name='password'
                        style={{ marginTop: 10 }}
                        rules={[
                            { required: true, message: '请输入密码' },
                            {
                                validator: (_, value) => {
                                    return !(new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")).test(value) ?
                                        Promise.reject('至少6个字符数字或者特殊字符') : Promise.resolve();
                                }
                            }
                        ]} >
                        <Input type="password" placeholder='请输入密码' />
                    </Form.Item>
                    <Form.Item name='confirmPassword'
                        style={{ marginTop: 10 }}

                        rules={[
                            { required: true, message: '请输入密码' },
                            {
                                validator: (_, value) => {
                                    return value != pageForm.getFieldValue("password") ? Promise.reject('两次密码不一致') : Promise.resolve();
                                }
                            },
                        ]} >
                        <Input type="password" placeholder='请再次输入密码' />
                    </Form.Item>
                </div>

                <div className="flex-row flex-justify-between ">
                    <span className='btn-register' onClick={() => {
                        navigate("/ailawyer/login")
                    }} >
                        账号登录
                    </span>
                    <span className='btn-register' onClick={() => {
                        navigate("/ailawyer/login/phone")
                    }}>
                        验证码登录
                    </span>
                </div>

            </Form>
        </div>
    );
}
addRoute("/ailawyer/register", Page);
export default Page;
