import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Dialog, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import Url from 'util/url';




function Page() {


    const metaCode = "paifuProject";

    const [pageForm] = Form.useForm()


    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })


    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            post(module.ac + '/my/account/name/update', {
                name: data.name
            }).then(res => {
                Toast.show({
                    content: '更新成功',
                })
                navigate(-1);
            })
        })
    }

    useEffect(() => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            pageForm.setFieldsValue({
                name: res.data.name
            });
        })
    }, [])

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>修改名称</NavBar>
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='submit' color='primary' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='name' label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}>
                        <Input />
                    </Form.Item>
                </>
            </Form>
        </>
    );
}
addRoute("/personal/edit/name", Page);
export default Page;
