import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Space, Toast, Image, Button, Tag, Form, Input, Grid } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, AppstoreOutline } from 'antd-mobile-icons'
import { FaHome } from "react-icons/fa";
import CaptchaButton from 'component/CaptchaButton';
import AuthUtil from 'util/auth'

function Page() {

    const [pageForm] = Form.useForm()

    const navigate = useNavigate();

    const pathname = window.location.pathname;

    const submitRegister = () => {
        pageForm.validateFields().then((values) => {
            post("/api/weixin/auth/v1.0/bind/account", {
                referralCode: pathname.substring(6, pathname.length),
                ...values
            })
                .then(res => {
                    Toast.show({
                        content: '注册成功',
                    })
                    AuthUtil.goHome()
                })
        })
    }

    return (
        <>
            <NavBar
                back="首页"
                backArrow={false}
                onBack={() => { AuthUtil.goHome() }}>注册</NavBar>
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <>

                        <Button block type='submit' color='primary' size='middle' onClick={submitRegister} style={{ marginTop: 10 }}>
                            提交
                        </Button>

                    </>
                }
            >

                <Form.Item name='phone' label="手机号" rules={[
                    { required: true, message: '请输入手机号' },
                    {
                        validator: (_, value) => {
                            return !(/^1[3-9]\d{9}$/).test(value) ? Promise.reject('手机号格式不正确') : Promise.resolve();
                        }
                    },
                ]}>
                    <Input placeholder='请输入手机号' type="number" />
                </Form.Item>
                <Form.Item name='smsCode' label="短信验证码"
                    rules={[{ required: true, message: '请输入短信验证码' }]}
                    extra={<CaptchaButton fill='none' size="small" business="bind" getPhone={() => {
                        return pageForm.validateFields(['phone']).then(res => {
                            return res.phone;
                        }, err => {
                            return null;
                        })
                    }}></CaptchaButton>} >
                    <Input placeholder='请输入验证码' />
                </Form.Item>
                {/* <Form.Item name='password' label="密码" rules={[
                    { required: true, message: '请输入密码' },
                    {
                        validator: (_, value) => {
                            return !(new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")).test(value) ?
                                Promise.reject('至少6个字符数字或者特殊字符') : Promise.resolve();
                        }
                    }
                ]} >
                    <Input type="password" placeholder='请输入密码' />
                </Form.Item>
                <Form.Item name='confirmPassword' label="确认密码"
                    rules={[
                        { required: true, message: '请输入密码' },
                        {
                            validator: (_, value) => {
                                return value != pageForm.getFieldValue("password") ? Promise.reject('两次密码不一致') : Promise.resolve();
                            }
                        },
                    ]} >
                    <Input type="password" placeholder='请再次输入密码' />
                </Form.Item> */}

            </Form>
        </>
    );
}
addRoute("/bind", Page);
addRoute("/bind/*", Page);
export default Page;
