import React, { useCallback, useEffect, useState, Component } from 'react'
import { post } from 'util/http';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

Quill.register('modules/imageResize', ImageResize);

class Page extends Component {
    constructor(props) {
        super(props);
        this.modules = {
            imageResize: {
                parchment: Quill.import('parchment')
            },
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'], // 加粗、下划线
                    // ['blockquote', 'code-block'],  // code-block -- 高亮显示
                    // [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序、无序
                    // [{ 'script': 'sub' }, { 'script': 'super' }],  // 上标下标
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'direction': 'rtl' }],

                    // [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                    [{ 'color': [] }, { 'background': [] }],  // 字体颜色、字体背景
                    // [{ 'font': [] }],
                    [{ 'align': [] }],
                    // ['link', 'image', 'video'],  // 链接、图片
                    // ['Undo', 'Redo'], // 撤销、还原
                    // ['clean']
                ],
                handlers: {
                    image: function () {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.click();
                        input.onchange = () => {
                            const file = input.files[0];
                            const fd = new FormData();
                            fd.append('file', file);
                            // const hide = message.loading('上传中...', 0);
                            axios({
                                method: 'post',
                                url: '/api/app/resource/v1.0/image/upload',
                                headers: { 'Content-Type': 'multipart/form-data' },
                                data: fd
                            }).then((res) => {
                                const range = this.quill.getSelection();
                                this.quill.insertEmbed(range.index, 'image', res.data.data);
                                let index = range ? range.index : 0;
                                this.quill.setSelection(index + 1);
                                // hide();
                            })
                        }
                    }
                }
            }
        }
    }
    render() {
        return (
            <ReactQuill
                theme="snow"
                readOnly={this.props.readOnly}
                modules={this.modules}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        );
    }
}


export default Page;