import React, { useEffect, useState } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Toast, Button, Form, Input, Grid, Radio } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import Privacy from './privacy'
import Aggrement from './agreement';
import AuthUtil from "util/auth";
import Url from 'util/url';
import './index.css'

function Page() {

    const [pageForm] = Form.useForm()
    const navigate = useNavigate();
    const [agreementChecked, setAgreementChecked] = useState(false);

    const submitLogin = () => {
        pageForm.validateFields().then((values) => {
            if (!agreementChecked) {
                Toast.show("请阅读并同意用户协议！");
                return;
            } else {
                post(module.auth + "/auth/v1.0/login", values)
                    .then(res => {
                        Toast.show({
                            content: '登录成功',
                        })
                        const url = Url.getParam("url");
                        if (url != null && url != '') {
                            window.location.href = url;
                        } else {
                            AuthUtil.goHome();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        window.document.title = "账号登录";
    }, [])

    return (
        <div className="lawyer-page lawyer-login-page" >
            {/* <NavBar
                back="首页"
                backArrow={false}
                onBack={() => { AuthUtil.goHome() }}>登录</NavBar> */}
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <div>
                        <Button block type='submit' color='success' size='middle' onClick={submitLogin} >
                            登录
                        </Button>
                        <div className="aggrement-privacy">
                            <Radio checked={agreementChecked} onChange={(v) => { setAgreementChecked(v) }}>已阅读及同意
                                <a className="s2" onClick={() => {
                                    Url.openPage(<Aggrement></Aggrement>);
                                }}>《律伴用户协议》</a>&nbsp;
                                <a className="s2" onClick={() => {
                                    Url.openPage(<Privacy></Privacy>);
                                }}>《隐私政策》</a>
                            </Radio>
                        </div>

                    </div>
                }
            >
                <div className='flex-column flex-align-center' style={{ padding: "86px 0px" }}>
                    <img src={window.PUBLIC_URL + '/static/images/ailawyer/logo-login.png'} style={{ width: 145, height: 32 }}></img>
                    <div className="font-26">律伴</div>
                </div>
                <div >
                    <Form.Item name='accountCode' rules={[{ required: true, message: '请输入账号' }]}>
                        <Input placeholder='请输入账号' />
                    </Form.Item>
                    <Form.Item name='password'
                        style={{ marginTop: 20 }}
                        rules={[{ required: true, message: '请输入密码' }]}>
                        <Input type="password" placeholder='请输入密码' />
                    </Form.Item>

                </div>
                <div className="flex-row flex-justify-between ">
                    <span className='btn-register' onClick={() => {
                        navigate("/ailawyer/register")
                    }} >
                        注册
                    </span>
                    <span className='btn-register' onClick={() => {
                        navigate("/ailawyer/login/phone")
                    }}>
                        验证码登录
                    </span>
                </div>


            </Form>
        </div>
    );
}
addRoute("/ailawyer/login", Page);
export default Page;
