import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, Button, Form, NavBar, Input, Toast, TextArea } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import regionData from 'util/region';
import CascaderSelect from 'component/CascaderSelect';
import ImageUpload from 'component/ImageUpload'
import DictPicker from 'component/DictPicker'

function Page() {


    const navigate = useNavigate();
    const [pageForm] = Form.useForm()

    const [regionVisible, setRegionVisible] = useState(false);

    const loadLawyerInfo = () => {
        post('/api/ailawyer/my/lawyer/info', {
        }).then(res => {
            const newData = res.data;
            newData.regionCodeSelect = newData.regionCode.split(",");
            pageForm.setFieldsValue({
                ...newData
            });
        })
    }


    useEffect(() => {
        loadLawyerInfo();
        // window.document.title = '编辑基本信息';
    }, [])

    const submitForm = () => {
        pageForm.validateFields().then((data) => {
            post('/api/ailawyer/my/lawyer/update', {
                ...data
            }).then(res => {
                Toast.show({
                    content: '保存成功！',
                })
            })
        })
    }


    return (
        <div className="laywer-account-base">
            <Form
                form={pageForm}
            >
                <List header=''>

                    <List.Item extra={<Form.Item name='isPublic'
                        rules={[{ required: true, message: '是否公开' }]}>
                        <DictPicker className='text-right' dictCode="TRUE_FALSE" />
                    </Form.Item>} >
                        是否公开
                    </List.Item>


                    <List.Item extra={<Form.Item name='name'
                        rules={[{ required: true, message: '请输入 姓名' }]}>
                        <Input className='text-right' />
                    </Form.Item>} >
                        姓名
                    </List.Item>

                    <List.Item extra={<Form.Item name='idCardCode'
                        rules={[{ required: true, message: '请输入身份证号' }]}>
                        <Input className='text-right' placeholder='请输入身份证号' />
                    </Form.Item>} >
                        身份证号
                    </List.Item>

                    <List.Item extra={<Form.Item name='school' >
                        <Input className='text-right' placeholder='请输入毕业院校' />
                    </Form.Item>} >
                        毕业院校
                    </List.Item>

                    <List.Item extra={<Form.Item name='education' >
                        <Input className='text-right' placeholder='请输入学历' />
                    </Form.Item>} >
                        学历
                    </List.Item>


                    <List.Item extra={<Form.Item name='companyName'
                        rules={[{ required: true, message: '请输入执业单位' }]}>
                        <Input className='text-right' placeholder='请输入执业单位' />
                    </Form.Item>} >
                        执业单位
                    </List.Item>

                    <Form.Item name='regionCode' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <Form.Item name='regionName' style={{ display: 'none' }} >
                        <Input type={"hidden"} />
                    </Form.Item>
                    <List.Item extra={<Form.Item name='regionCodeSelect'
                        onClick={() => {
                            setRegionVisible(true)
                        }} >
                        <CascaderSelect
                            visible={regionVisible}
                            setVisible={(visible) => {
                                setRegionVisible(visible)
                            }}
                            initData={regionData}
                            onChange={(v, e, extend) => {
                                pageForm.setFieldValue("regionName", extend.fullLabel.join(""));
                                pageForm.setFieldValue("regionCode", extend.fullValue.join(","));
                            }} />
                    </Form.Item>} >
                        工作地区
                    </List.Item>



                    <List.Item extra={<Form.Item name='certificateCode'
                        rules={[{ required: true, message: '请输入执业证号' }]}>
                        <Input className='text-right' placeholder='请输入执业证号' />
                    </Form.Item>} >
                        执业证号
                    </List.Item>

                    <List.Item extra={<Form.Item name='certificateImage'
                        rules={[{ required: true, message: '请上传执业证' }]}>
                        <ImageUpload maxCount={2} />
                    </Form.Item>} >
                        执业证
                    </List.Item>

                    <List.Item extra={<Form.Item name='idCardImage'
                        rules={[{ required: true, message: '请上传身份证' }]}>
                        <ImageUpload maxCount={2} />
                    </Form.Item>} >
                        身份证
                    </List.Item>

                    <Form.Item name='remark'
                        rules={[{ required: true, message: '请填写律师介绍' }]}>
                        <TextArea rows={6} placeholder='请填写律师介绍' />
                    </Form.Item>

                </List>
            </Form>
            <div className="fixed-btn-wrapper">
                <Button color='success' onClick={() => {
                    submitForm()
                }} block>保存</Button>
            </div>

        </div>
    );
}
export default Page;
