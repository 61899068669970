import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, NavBar, Tabs, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import ImgUtil from 'util/img'
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import './index.css'

function Page() {

    const metaCode = "aiLawyerProtocolOrder";
    const navigate = useNavigate();

    const [param, setParam] = useState({
        // status: 'approvaling'
    });

    const [robotData, setRobotData] = useState({
        records: []
    });


    useEffect(() => {
        window.document.title = "文书订单";
    }, [])

    return (
        <div className="lawyer-page mine-lawyer-protocol-order-page" >
            {/* <NavBar onBack={() => {
                navigate(-1)
            }} >文书订单</NavBar> */}

            <Tabs onChange={(key) => {
                if (key == 'all') {
                    setParam({
                        ...param,
                        status: ''
                    })
                } else {
                    setParam({
                        ...param,
                        status: key
                    })
                }
            }}>
                <Tabs.Tab title='全部' key='all'>
                </Tabs.Tab>
                <Tabs.Tab title='制作中' key='making'>
                </Tabs.Tab>
                <Tabs.Tab title='审核中' key='approvaling'>
                </Tabs.Tab>
                <Tabs.Tab title='已完成' key='finish'>
                </Tabs.Tab>
            </Tabs>


            <ScrollList
                url={'/api/ailawyer/protocol/order/search-by-lawyer'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(item => (

                        <div className="order-item" key={item.code}
                            onClick={() => {
                                if (item.status == 'making') {
                                    Toast.show({
                                        content: '文书制作中，请耐心等待',
                                    })
                                    return;
                                } else {
                                    navigate("/ailawyer/mine/lawyer/order/detail?code=" + item.code);
                                }
                            }}>
                            <div className="flex-row t1">
                                <Image src={ImgUtil.getHeadimg(item.creatorCode)} className="headimg" />
                                <div className="flex-column flex-1">
                                    <div className="flex-row ml-10">
                                        <div className="font-13">{item.creatorName}</div>
                                        <div className="green-tag">{item.protocolName}</div>
                                    </div>
                                    <div className=" ml-10">
                                        <span className="gray-tag  ">{moment(item.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                    </div>
                                </div>
                                <div className={"item-status status-" + item.status}>
                                    {getMetaLabel('PROTOCOL_ORDER_STATUS', item.status)}
                                </div>
                            </div>

                        </div>
                    ))
                }

            </ScrollList>
        </div>
    );
}
addRoute("/ailawyer/mine/lawyer/order", Page);
export default Page;
