import { post } from './http.js';
import AuthUtil from './auth.js';
import { Modal, Toast } from 'antd-mobile'
import { QRCodeCanvas } from 'qrcode.react'


const WeixinUtil = {
    jsConfig: () => {
        post("/api/weixin/js/config", {
            url: window.location.href
        }).then(res => {
            window.wx.config({
                debug: false,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'showMenuItems'],
                ...res.data
            })

        })
    },
    goPay: (orderCode, callback) => {
        if (!AuthUtil.isInWeixin()) {

            Modal.alert({
                title: '请在微信中打开并支付',
                content: <div style={{ textAlign: 'center' }}><QRCodeCanvas
                    value={window.APP_CONFIG.webDomain + "/api/weixin/auth/v1.0/auth/login?targetUrl=/ec/payment/weixin?orderCode=" + orderCode}
                >
                </QRCodeCanvas></div>,
                confirmText: "关闭",
                onConfirm: () => {
                    Modal.clear()
                }
            })


        } else {
            WeixinUtil.submitPayment(orderCode, callback)
        }
    },
    submitPayment: (orderCode, callback) => {
        post("/api/ec/weixin/payment/create", {
            orderCode: orderCode
        }).then(res => {
            const responseData = JSON.parse(res.data.responseData);
            responseData.timestamp = responseData.timeStamp;
            responseData.success = function (res) {
                if (callback) {
                    callback(orderCode)
                }
            }
            window.wx.chooseWXPay(responseData);
        })
    },
    updateShareData: (title, desc, link, imgUrl) => {
        window.wx.ready(function () {
            window.wx.updateAppMessageShareData({
                title: title,
                desc: desc,
                link: link,
                imgUrl: imgUrl,
                success: function () {
                    console.log("updateAppMessageShareData 设置成功")
                }
            })
            window.wx.updateTimelineShareData({
                title: title,
                desc: desc,
                link: link,
                imgUrl: imgUrl,
                success: function () {
                    console.log("updateTimelineShareData 设置成功")
                }
            })
        });


    }
}

export default WeixinUtil;