import React, { useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { post } from 'util/http';
import { NavBar, Toast, Button, Form, Input, Space } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import RichTextEditor from 'component/RichTextEditor'
import Url from 'util/url';
import ImageUpload from 'component/ImageUpload'


function Page() {

    const [pageForm] = Form.useForm()
    const navigate = useNavigate();

    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            const url = '/api/app/my/meta/data/save';
            post(url, {
                metaCode: "cmsArticle",
                datas: {
                    ...data,
                    channelCode: "lawyerHonor",
                    fullChannelCode: "lawyerHonor",
                    channelTitle: "律师荣誉",
                    parentCode: "-1",
                    tags: window.LOGIN_INFO.code
                }
            }).then(res => {
                Toast.show({
                    content: '保存成功',
                })
                navigate(-1);
            })
        })
    }

    const deleteArticle = () => {
        const url = '/api/app/my/meta/data/delete';
        post(url, {
            metaCode: "cmsArticle",
            id: Url.getParam('id')
        }).then(res => {
            Toast.show({
                content: '删除成功',
            })
            navigate(-1);
        })
    }
    useEffect(() => {
        if (Url.getParam('id')) {
            const url = '/api/app/my/meta/data/get';
            post(url, {
                metaCode: "cmsArticle",
                id: Url.getParam('id')
            }).then(res => {
                pageForm.setFieldsValue(res.data);
            })
        }
        window.document.title = "编辑照片";

    }, [])

    return (
        <div className="lawyer-page">
            {/* <NavBar onBack={() => { navigate(-1) }}>编辑报道</NavBar> */}
            <Form
                form={pageForm}
                footer={
                    <div className="flex-column-center">
                        <div className="btn-submit" color='success' size='mini' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </div>
                        {Url.getParam("id") != null && <button className="btn-delete" size='mini' onClick={deleteArticle} style={{ marginTop: 10 }}>
                            删除
                        </button>}
                    </div>
                }
            >
                <Form.Item style={{ display: 'none' }}
                    name="id">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name='title' label="标题"
                    rules={[{ required: true, message: '请输入标题' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='titleImg' label="图片" >
                    <ImageUpload maxCount={1} multiple={true} onChange={(res) => {
                    }} />
                </Form.Item>

            </Form>
        </div>
    );
}
addPage("/ailawyer/mine/lawyer/news/honor", Page);
export default Page;
