

import React, { useEffect, useState } from 'react'
import { Image, List, Button, Ellipsis, Dropdown, CascaderView, InfiniteScroll, DotLoading } from 'antd-mobile'
import { post } from 'util/http';

export const Page = ({
    children,
    url,
    data,
    setData,
    param,
    setParam,
    showMore = true,
    ...nextProps
}) => {
    const [hasMore, setHasMore] = useState(false);

    const loadMore = () => {
        const current = data.current == null ? 1 : data.current + 1;
        return post(url, { ...param, current: current }).then(res => {
            const newRecords = data.records.concat(res.data.records);
            setData({ ...res.data, records: newRecords });
            setHasMore(res.data.current * res.data.size < res.data.total);
        })
    }
    useEffect(() => {
        post(url, param).then(res => {
            setData(res.data);
            setHasMore(res.data.current * res.data.size < res.data.total);
        })
    }, [param])

    return (
        <>
            <List  {...nextProps}>
                {(children)}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} >
                {showMore ? (hasMore ? (
                    <>
                        <span>加载中</span>
                        <DotLoading />
                    </>
                ) : (
                    <span>--- 没有更多了 ---</span>
                )) : null}
            </InfiniteScroll>
        </>
    );
}

export default Page;