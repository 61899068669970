import React, { useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Toast, Button, Form, ImageUploader } from 'antd-mobile'
import ImageUpload from 'component/ImageUpload'

import { useNavigate, useLocation } from 'react-router-dom';

function Page() {

    const [pageForm] = Form.useForm()

    const navigate = useNavigate();
    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            post(module.ac + '/my/account/attr/save', {
                metaCode: 'headimg',
                metaValue: data.headimg
            }).then(res => {
                Toast.show({
                    content: '更新成功',
                })
                navigate(-1);
            })
        })
    }

    useEffect(() => {
        post(module.ac + '/my/account/info', {
        }).then(res => {
            const newData = {
                headimg: [{
                    url: res.data.attributes.headimg
                }]
            }
            pageForm.setFieldsValue(newData);
        })

        window.document.title = "修改头像";
    }, [])




    return (
        <div className="lawyer-page">
            {/* <NavBar onBack={() => { navigate(-1) }}>修改头像</NavBar> */}
            <Form
                form={pageForm}
                footer={
                    <>
                        <Button block type='submit' color='success' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name='headimg' label="头像"
                        rules={[{ required: true, message: '请选择头像' }]}>
                        <ImageUpload
                        />
                    </Form.Item>
                </>
            </Form>
        </div>
    );
}
addRoute("/ailawyer/mine/base/headimg", Page);
export default Page;
