import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Form, ImageUploader, NavBar, Input, Toast } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import WeixinUtil from 'util/weixin';
import NumberInput from 'component/NumberInput'

function Page() {


    const navigate = useNavigate();
    const [pageForm] = Form.useForm()

    const [product, setProduct] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        WeixinUtil.jsConfig();

        post('/api/ec/public/product/get', {
            productCode: "AilawyerLawyerRenewal"
        }).then(res => {
            setProduct(res.data);
        })

    }, [])


    const submitForm = () => {
        pageForm.validateFields().then((data) => {
            post("/api/ec/public/order/create", {
                description: "律师账号续费",
                orderProducts: [{
                    productCode: "AilawyerLawyerRenewal",
                    number: data.number
                }]
            }).then(res => {
                WeixinUtil.goPay(res.data.code, function (orderCode) {
                    Toast.show({
                        content: '支付完成！',
                    })
                    navigate(-1);
                })
            })
        })
    }


    return (
        <div >
            {/* <NavBar onBack={() => { navigate(-1) }}>账号续费</NavBar> */}
            <Form
                form={pageForm}
                style={{ backgroundColor: "#ff0000" }}
            >

                <List header='' >

                    <List.Item extra={<span style={{ paddingRight: 10 }}>{product.price}元/月</span>} >
                        平台使用费用
                    </List.Item>



                    <List.Item extra={<Form.Item name='number'
                        rules={[{ required: true, message: '请输入开通时长' }]}>
                        <NumberInput minValue={1} initialValues={0} onChange={(v) => {
                            setTotalPrice(v * product.price);
                        }} />
                    </Form.Item>} >
                        开通时长
                    </List.Item>

                    <List.Item extra={<span style={{ paddingRight: 10 }}>{totalPrice}元</span>} >
                        费用合计
                    </List.Item>


                </List>
            </Form >
            <div className="pd-20">
                <Button color='success' onClick={() => { submitForm() }} block>确认续费</Button>
            </div>

        </div>
    );
}
addPage("/ailawyer/mine/lawyer/account/renewal", Page);
export default Page;
