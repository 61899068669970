import React, { useState, useEffect, useRef } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import { getMetaLabel } from 'util/meta';
import ShareCanvas from 'component/ShareCanvas';
import PdfView from 'component/PdfView';

// import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import { Document, Page, pdfjs } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.js'

// import Viewer from '@phuocng/react-pdf-viewer';


function MyDocument() {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {


    }, [])



    return (

        <div style={{ height: '100vh' }}>
            <PdfView url={window.PUBLIC_URL + "/test.pdf"} />
            {/* <Document file="/test.pdf"
                onLoadSuccess={({ numPages }) => {
                    setNumPages(numPages)
                }}>
                <Page pageNumber={pageNumber}></Page>
            </Document>
            {pageNumber} of {numPages} */}

        </div>
    );
}
addPage("/test/pdf", MyDocument);
export default MyDocument;
