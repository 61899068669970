

import React, { useState, useCallback, useEffect } from 'react'
import { Picker, Button, List } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';

const Page = ({
    children,
    dictCode,
    value,
    onChange = () => {
    },
}) => {
    const [visible, setVisible] = useState(false)
    const [options, setOptions] = useState([]);
    const [label, setLabel] = useState("");

    const loadOptions = useCallback(() => {
        post(module.app + "/dict/v1.0/get-by-code", { code: dictCode })
            .then(res => {
                var newLabel = "选择";
                const newOptions = res.data.items.map((item) => {
                    if (item.itemValue == value) {
                        newLabel = item.itemLabel;
                    }
                    return {
                        label: item.itemLabel,
                        value: item.itemValue
                    }
                })
                setOptions([newOptions]);
                setLabel(newLabel);
            })
    }, [])

    useEffect(() => {
        loadOptions();
    }, [dictCode])



    useEffect(() => {
        if (options.length > 0) {
            var newLabel = "选择";
            options[0].map((item) => {
                if (item.value == value) {
                    newLabel = item.label;
                }
                setLabel(newLabel);
            })
        }
    }, [value])
    return (
        <List.Item extra={<>
            {label}
            <Picker
                columns={options}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                value={value}
                onConfirm={(v, extend) => {
                    setLabel(extend.items[0].label);
                    onChange(v[0], extend);
                }}
            />
        </>}
            onClick={() => {
                setVisible(true)
            }}
            clickable>
            {children}
        </List.Item>

    );
}

export default Page;


