import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, Dialog, NavBar, Card, Toast, Badge, Grid, Modal } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../navbar/index'
import WeixinUtil from 'util/weixin';

import {
    UserOutline,
    FileOutline,
    PhonebookOutline,
    TeamFill,
    PayCircleOutline,
    FillinOutline
} from 'antd-mobile-icons'
import {
    ShoppingCartOutlined,
    SolutionOutlined,
    SecurityScanOutlined, QuestionCircleOutlined, NotificationOutlined, MoneyCollectOutlined, IdcardOutlined, MoneyCollectFilled, FileFilled, SoundFilled, IdcardFilled, UpSquareFilled, PieChartFilled, OrderedListOutlined
} from '@ant-design/icons';

import './index.css'
import { FaUserFriends } from 'react-icons/fa';

function Page() {

    const navigate = useNavigate();

    const [account, setAccount] = useState({
        attributes: {}
    });



    const tabs = [
        // {
        //     key: '/ai/coin/my',
        //     title: '我的i币',
        //     icon: <CollectMoneyOutline className='font-26 mb-5' />,
        //     badge: Badge.dot,
        // },
        // {
        //     key: '/ai/coin/buy',
        //     title: 'i币充值',
        //     icon: <PayCircleOutline className='font-26 mb-5' />,
        //     badge: Badge.dot,
        // }，
        {
            key: '/ailawyer/mine/base',
            title: '基本信息',
            icon: <UserOutline className='font-26 mb-5' />,
            badge: Badge.dot,
        }
    ]

    const goLawyer = () => {
        const lawyerStatus = account.attributes.lawyerStatus;
        if (lawyerStatus == null || lawyerStatus == "") {
            navigate("/ailawyer/mine/lawyer/open")
        } else if (lawyerStatus == "applying") {
            Dialog.alert({
                content: '你已经申请了律师资格，正在审核中，请耐心等待',
            });
        } else if (lawyerStatus == "normal") {
            navigate("/ailawyer/mine/lawyer/index")
        } else if (lawyerStatus == "expire") {
            Modal.confirm({
                content: '您的律师资格已过期，请及时续费',
                confirmText: '去续费',
                onConfirm: function () {
                    navigate("/ailawyer/mine/lawyer/renewal");
                }
            });
        }
    }

    useEffect(() => {

        post('/api/ac/my/account/info', {
        }).then(res => {
            setAccount(res.data);
        })
        WeixinUtil.jsConfig();
        WeixinUtil.updateShareData("律伴AI", "AI与入住律师合作制作民间借贷、婚姻文书，更便宜",
            window.APP_CONFIG.h5Domain,
            window.PUBLIC_URL + "/static/images/ailawyer/logo1.png");

        window.document.title = "我的";

    }, [])


    return (
        <div className="lawyer-mine-page" >
            {/* <NavBar backArrow={null} >我的</NavBar> */}
            <div>
                <div className="mine-head">
                    <div >
                        <Image
                            src={account.attributes.headimg}
                            style={{ borderRadius: 20 }}
                            fit='cover'
                            className="headimg"
                        />
                    </div>
                    <div className='myname'>
                        <div className="name">{account.name}</div>
                        <div className="phone">{account.phone} </div>
                    </div>
                </div>
            </div>
            <div className="mine-service">
                <Card title='我的服务' >
                    <Grid columns={4} gap={8}>

                        {tabs.map(item => (
                            <Grid.Item
                                style={{ marginBottom: 15 }}
                                key={item.key} onClick={() => {
                                    if (item.tip) {
                                        Toast.show({
                                            content: '功能待实现！',
                                        })
                                    } else if (item.clickFn != null) {
                                        item.clickFn();
                                    } else {
                                        navigate(item.key);
                                    }
                                }}>
                                <div className='flex-column-center  flex-justify-center'>
                                    {item.icon}
                                    <span className="icon-title">{item.title}</span>
                                </div>
                            </Grid.Item>
                        ))}
                        <Grid.Item onClick={() => {
                            navigate("/ailawyer/mine/protocol")
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <FileOutline className='font-26 mb-5' />
                                <span className="icon-title">我的文书</span>
                            </div>
                        </Grid.Item>
                        {/* 
                        <Grid.Item onClick={() => {
                            Toast.show({
                                content: '功能待实现！',
                            })
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                < PayCircleOutline className='font-26 mb-5' />
                                <span className="icon-title">充值</span>
                            </div>
                        </Grid.Item> */}

                        <Grid.Item onClick={() => {
                            Toast.show({
                                content: '功能待实现！',
                            })
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <ShoppingCartOutlined className='font-26 mb-5' />
                                <span className="icon-title">订单&发票</span>
                            </div>
                        </Grid.Item>

                        <Grid.Item onClick={() => {
                            window.location.href = "tel://186100000";
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <PhonebookOutline className='font-26 mb-5' />
                                <span>客服电话</span>
                            </div>
                        </Grid.Item>

                        <Grid.Item onClick={() => {
                            navigate("/ailawyer/mine/notice")
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <NotificationOutlined className='font-26 mb-5' />
                                <span className="icon-title">平台通知</span>
                            </div>
                        </Grid.Item>
                        <Grid.Item onClick={() => {
                            navigate("/ailawyer/mine/help")
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <QuestionCircleOutlined className='font-26 mb-5' />
                                <span className="icon-title">玩法&规则</span>
                            </div>
                        </Grid.Item>
                        <Grid.Item onClick={() => {
                            navigate("/ailawyer/mine/feedback")
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <FillinOutline className='font-26 mb-5' />
                                <span className="icon-title">反馈&投诉</span>
                            </div>
                        </Grid.Item>

                    </Grid>

                </Card>



            </div>
            <div className="lawyer-service">
                <Card title='律师服务' >
                    <Grid columns={4} gap={8}>

                        <Grid.Item onClick={() => {
                            goLawyer()
                        }}>
                            <div className='flex-column-center  flex-justify-center'>
                                <TeamFill className='lawyer-service-icon font-26 mb-5' />
                                <span>律师账号</span>
                            </div>
                        </Grid.Item>

                        {(account.attributes.lawyerStatus == 'normal' || account.attributes.lawyerStatus == 'expire') &&
                            <>
                                {/* <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        navigate("/ailawyer/mine/lawyer/base")
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <UpSquareFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">基本信息</span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        navigate("/ailawyer/mine/lawyer/intro")
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <IdcardFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">相册动态</span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        navigate("/ailawyer/mine/lawyer/protocol")
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <SoundFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">文书服务</span>
                                    </div>
                                </Grid.Item> */}
                                <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        navigate("/ailawyer/mine/lawyer/order")
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <FileFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">文书订单</span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        Toast.show({
                                            content: "待开发"
                                        })
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <MoneyCollectFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">客户发票</span>
                                    </div>
                                </Grid.Item>
                                <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        Toast.show({
                                            content: "待开发"
                                        })
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <PieChartFilled className='lawyer-service-icon font-26 mb-5' />
                                        <span className="icon-title">我的数据</span>
                                    </div>
                                </Grid.Item>
                                {/* <Grid.Item
                                    style={{ marginBottom: 15 }}
                                    onClick={() => {
                                        Toast.show({
                                            content: "待开发"
                                        })
                                    }}>
                                    <div className='flex-column-center  flex-justify-center'>
                                        <MoneyCollectOutlined className='font-26 mb-5' />
                                        <span>我的收入</span>
                                    </div>
                                </Grid.Item> */}
                            </>
                        }

                    </Grid>

                </Card> </div>
            <CustomNavbar />
        </div>
    );
}
addPage("/ailawyer/mine", Page);
export default Page;
