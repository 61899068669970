import React, { useState, useEffect } from 'react'
import { post } from 'util/http';

import WeixinUtil from 'util/weixin'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { NavBar, Space, Toast, Image, Button, Tag, Form, Input, Modal } from 'antd-mobile'
import { addPage, addRoute } from 'component/DynamicRotes';


function Page() {
    const navigate = useNavigate();
    const productCode = "AiCoin"
    const [product, setProduct] = useState({});

    const [selectNumber, setSelectNumber] = useState(1000);

    const buyNumber = [
        1000, 2000, 5000, 10000, 20000, 50000
    ]
    const getProduct = () => {
        post("/api/ec/public/product/get", {
            productCode: productCode
        }).then(res => {
            setProduct(res.data);
        });
    }

    const createOrder = (number) => {
        post("/api/ec/public/order/create", {
            description: "i币购买",
            orderProducts: [{
                productCode: productCode,
                number: number
            }]
        }).then(res => {
            WeixinUtil.goPay(res.data.code, function () {
                Modal.confirm({
                    content: '支付完成，查看i币余额',
                    okText: '查看',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/ai/coin/my");
                    }
                });
            })
        })
    }

    useEffect(() => {
        getProduct();
        WeixinUtil.jsConfig();
    }, [])
    return (
        <div>
            <NavBar onBack={() => { navigate(-1) }} >i币充值</NavBar>
            {product.code != null && <div className='coin-buy-bg' >
                <div className='coin-buy-content'>
                    <div className='coin-buy-title'>
                        i币充值
                        <div className='font-10'>1i币={product.price}元</div>
                    </div>
                    <div className="coin-buy-list">
                        {buyNumber.map((item, index) => {

                            return <div key={index} className={selectNumber == item ? "coin-buy-item coin-buy-item-select" : "coin-buy-item "}
                                onClick={() => {
                                    setSelectNumber(item)
                                }}>
                                <div className='font-10'>{item}i币</div>
                                <div>￥{item * product.price} </div>
                            </div>
                        })}
                    </div>
                    <Button
                        className='coin-buy-btn'
                        block color='primary' onClick={() => {
                            createOrder(selectNumber)
                        }} >去充值</Button>


                </div>


            </div>}
        </div>
    );
}
addRoute("/ai/coin/buy", Page);
export default Page;
