import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import Url from 'util/url';





function Page() {

    const metaCode = "paifuWorker";

    const navigate = useNavigate();

    const [param, setParam] = useState({
        metaCode: metaCode
    });




    useEffect(() => {
        post("/api/weixin/js/config", {
            url: window.location.href
        }).then(res => {
            window.wx.config({
                debug: false,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'showMenuItems'],
                ...res.data
            })
        })
    }, [])

    const createOrder = (produtCode) => {
        post("/api/ec/public/order/create", {
            description: "i币购买",
            orderProducts: [{
                productCode: produtCode,
                number: 10
            }]
        }).then(res => {
            createPayment(res.data.code)
        })
    }

    const createPayment = (orderCode) => {
        post("/api/ec/weixin/payment/create", {
            orderCode: orderCode
        }).then(res => {
            const responseData = JSON.parse(res.data.responseData);
            responseData.timestamp = responseData.timeStamp;
            responseData.success = function (res) {
                Modal.confirm({
                    content: '支付完成，前往查看积分。',
                    okText: '查看',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/personal/points");
                    }
                });
            }
            window.wx.chooseWXPay(responseData);
        })
    }

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>积分充值</NavBar>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>30积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值30积分30元</div>
                <Button block color='primary' onClick={() => {
                    createOrder("AiCoin");
                }} >￥30购买</Button>
            </div>

        </>
    );
}
addPage("/ec/product/buy", Page);
export default Page;
