import React, { useState, useEffect } from 'react'
import { addRoute, DynamicPages, setBreadcrumbs } from 'component/DynamicRotes';

import './admin.css'

function App() {


    return (
        <>
            <div >
                <DynamicPages />
            </div>
        </>
    );
}
addRoute("*", App);
export default App;
