import React, { useCallback, useEffect, useRef, Component } from 'react'
import axios from "axios";
import { List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import Url from 'util/url';
import { CloseOutlined, DownCircleOutlined, DownloadOutlined } from '@ant-design/icons';

class Page extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();

        this.showCanvas = props.showCanvas;

        this.scale = () => {
            const ratio = window.devicePixelRatio || 1;
            const canvas = this.canvasRef.current;
            canvas.sourceWidth = canvas.width;
            canvas.sourceHeight = canvas.height;
            canvas.width = canvas.width * ratio;
            canvas.height = canvas.height * ratio;
            canvas.style.width = canvas.width / ratio + 'px';
            canvas.style.height = canvas.height / ratio + 'px';
            const context = this.canvasRef.current.getContext('2d');
            context.scale(ratio, ratio);
        }

        this.getSourceWidth = () => {
            return this.canvasRef.current.sourceWidth;
        }
        this.getSourceHeight = () => {
            return this.canvasRef.current.sourceHeight;
        }
        this.getWidth = () => {
            return this.canvasRef.current.width;
        }
        this.getHeight = () => {
            return this.canvasRef.current.height;
        }
        this.fillQrcode = async (text, x, y, width = 60, height = 60) => {
            var div = window.document.createElement("div")
            var qrcode = new window.QRCode(div, {
                text: text,
                width: width,
                height: height,
                colorDark: "#000000",
                colorLight: "#ffffff",
                // correctLevel: QRCode.CorrectLevel.H
            });
            const context = this.canvasRef.current.getContext('2d');
            const image = new Image();
            image.src = div.children[0].toDataURL();
            image.crossOrigin = "Anonymous";
            return await new Promise((resolve, reject) => {
                image.onload = () => {
                    context.drawImage(image, x, y, width, height);
                    resolve();
                };
            })
        }
        this.fillTag = (text, x, y, color, backgroud, size = 14) => {
            const context = this.canvasRef.current.getContext('2d');
            context.fillStyle = backgroud;
            // var metrics = context.measureText(text);
            // var testWidth = metrics.width;
            var testWidth = text.length * size + 30;
            this.fillRoundRect(x - 5, y, testWidth, size + 10, 10, backgroud);
            context.fillStyle = color;
            context.font = `${size}px Arial`;
            context.fillText(text, x + 10, y + 16);
        }
        this.fillRect = (x, y, w, h, color) => {
            const context = this.canvasRef.current.getContext('2d');
            context.fillStyle = color;
            context.fillRect(x, y, w, h);
        }
        this.fillRoundRect = (x, y, w, h, r, color) => {
            const context = this.canvasRef.current.getContext('2d');
            context.fillStyle = color;
            context.beginPath();
            context.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5);
            context.lineTo(w - r + x, y);
            context.arc(w - r + x, r + y, r, Math.PI * 1.5, Math.PI * 2);
            context.lineTo(w + x, h + y - r);
            context.arc(w - r + x, h - r + y, r, 0, Math.PI * 0.5);
            context.lineTo(r + x, h + y);
            context.arc(r + x, h - r + y, r, Math.PI * 0.5, Math.PI);
            context.closePath();
            context.fill();
        }

        this.fillText = (text, x, y, color, size = 14) => {
            const context = this.canvasRef.current.getContext('2d');
            context.fillStyle = color;
            context.font = `${size}px Arial`;
            context.fillText(text, x, y);
        }
        this.getTextLength = (text, size = 14) => {
            const context = this.canvasRef.current.getContext('2d');
            context.font = `${size}px Arial`;
            return context.measureText(text).width;
        }
        this.wrapFillText = (text, x, y, color, size = 14, maxWidth, lineHeight, maxLineCount = 2) => {
            const context = this.canvasRef.current.getContext('2d');
            context.fillStyle = color;
            context.font = `${size}px Arial`;
            var words = text.split('');
            var line = '';
            var lineCount = 0;
            for (var i = 0; i < words.length; i++) {
                var testLine = line + words[i];
                var metrics = context.measureText(testLine);
                var testWidth = metrics.width;
                if (testWidth > maxWidth && i > 0) {
                    lineCount++;
                    if (lineCount >= maxLineCount) {
                        line = line + '...';
                        break;
                    }
                    context.fillText(line, x, y);
                    line = words[i];
                    y += lineHeight;
                } else {
                    line = testLine;
                }
            }
            lineCount++;
            context.fillText(line, x, y);
        }
        this.drawImg = async (img, x, y, w, h) => {
            const context = this.canvasRef.current.getContext('2d');
            const image = new Image();
            image.src = img;
            image.crossOrigin = "Anonymous";
            return await new Promise((resolve, reject) => {
                image.onload = () => {
                    context.drawImage(image, x, y, w, h);
                    resolve();
                };
            })
        }

        this.drawRoundImg = async (img, x, y, w, h, r) => {
            const context = this.canvasRef.current.getContext('2d');
            const image = new Image();
            image.src = img;
            image.crossOrigin = "Anonymous";
            return await new Promise((resolve, reject) => {
                image.onload = () => {

                    context.save();
                    context.beginPath();
                    context.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5);
                    context.lineTo(w - r + x, y);
                    context.arc(w - r + x, r + y, r, Math.PI * 1.5, Math.PI * 2);
                    context.lineTo(w + x, h + y - r);
                    context.arc(w - r + x, h - r + y, r, 0, Math.PI * 0.5);
                    context.lineTo(r + x, h + y);
                    context.arc(r + x, h - r + y, r, Math.PI * 0.5, Math.PI);
                    context.closePath();
                    context.clip();
                    context.drawImage(image, x, y, w, h);
                    context.restore();
                    resolve();
                };
            })
        }
        this.show = () => {
            this.setState({
                show: true
            })
        }
        this.transferToImg = () => {
            const canvas = this.canvasRef.current;
            const dataURL = canvas.toDataURL();
            const img = window.document.getElementById('share-img');
            img.style.width = canvas.width / (window.devicePixelRatio || 1) + "px";
            img.style.height = canvas.height / (window.devicePixelRatio || 1) + "px";
            img.src = dataURL;
        }
        this.dowloadShare = () => {
            const canvas = this.canvasRef.current;
            const dataURL = canvas.toDataURL();
            const a = document.createElement('a');
            a.href = dataURL;
            a.download = `share.png`;
            a.click();
        }
        this.saveShare = () => {
            const canvas = this.canvasRef.current;
            const imageDataURL = canvas.toDataURL(); // 转换为Base64编码的图像数据URL
            // 将数据URL转换为Blob对象
            const byteString = atob(imageDataURL.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: 'image/png' });

            // const imageDataURL = canvas.toDataURL('image/png');
            const newUploadData = new FormData();
            newUploadData.append('file', blob, 'canvas_image.png');

            return new Promise((resolve, reject) => {
                const url = '/api/app/resource/v1.0/image/upload';
                axios.post(url, newUploadData).then((res) => {
                    return resolve({
                        url: res.data.data
                    });
                }, (err) => {
                    console.log(err);
                    reject && reject(err);
                })
            })
        }
        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.scale();
    }
    render() {
        return (
            <div className="share-canvas-bg" style={{ display: this.state.show ? "block" : "none" }}>
                <canvas ref={this.canvasRef} className="share-canvas" width={this.props.width || window.innerWidth} height={this.props.height || 375} />
                <img id="share-img" alt="" className="share-canvas-bg-img" />

                <div className="share-btn-group font-12">
                    <DownloadOutlined className="font-30 mb-10" onClick={() => {
                        this.dowloadShare();
                    }} />
                    长按上方图片保存或者点此下载
                    <CloseOutlined className="share-btn-close" onClick={() => {
                        this.setState({
                            show: false
                        })
                    }} />
                </div>

            </div>
        );
    }
}


export default Page;