import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import CascaderSelect from 'component/CascaderSelect';
import { post } from 'util/http';
import module from 'util/module';
import regionData from 'util/region';
import { NavBar, Dialog, Toast, Image, Button, Tag, Form, Input, TextArea } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'




function Page() {


    const metaCode = "cmsFeedback";

    const [pageForm] = Form.useForm()


    const navigate = useNavigate();

    const [param, setParam] = useState({
        step: 1
    })



    const submitStep = () => {
        pageForm.validateFields().then((data) => {
            data.creatorName = window.LOGIN_INFO.name;
            const url = '/api/app/my/meta/data/save';
            post(url, {
                metaCode: metaCode,
                datas: data
            }).then(res => {
                Toast.show({
                    content: '意见反馈成功，感谢您的宝贵意见！',
                })
                navigate(-1)
            })
        })
    }

    useEffect(() => {
        window.document.title = "反馈&投诉"
    }, [])

    return (
        <div className="lawyer-page">
            {/* <NavBar onBack={() => { navigate(-1) }}>意见反馈</NavBar> */}
            <Form
                form={pageForm}
                // layout='horizontal'
                footer={
                    <>
                        <Button block type='submit' color='success' size='middle' onClick={submitStep} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                    </>
                }
            >
                <>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>


                    <Form.Item name='content' label="反馈内容">
                        <TextArea
                            placeholder='请输入反馈内容'
                            maxLength={2000}
                            rows={8}
                            showCount
                        />
                    </Form.Item>

                </>
            </Form>
        </div>
    );
}
addRoute("/ailawyer/mine/feedback", Page);
export default Page;
