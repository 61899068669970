import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { NavBar, Space, Toast, Image, Button, Tag, Form, Input, Grid } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LocationFill, PhoneFill } from 'antd-mobile-icons'
import AuthUtil from 'util/auth'
import Url from 'util/url';



function Page() {

    const [pageForm] = Form.useForm()

    const navigate = useNavigate();

    const submitLogin = () => {
        pageForm.validateFields().then((values) => {
            post(module.auth + "/auth/v1.0/login", values)
                .then(res => {
                    Toast.show({
                        content: '登录成功',
                    })
                    const url = Url.getParam("url");
                    if (url != null && url != '') {
                        window.location.href = url;
                    } else {
                        AuthUtil.goHome()
                    }
                })
        })
    }

    return (
        <>
            <NavBar back="首页"
                backArrow={false}
                onBack={() => { AuthUtil.goHome() }}>登录</NavBar>
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <>

                        <Button block type='submit' color='primary' size='middle' onClick={submitLogin} style={{ marginTop: 10 }}>
                            登录
                        </Button>
                        <Grid columns={2} gap={8}>
                            <Grid.Item>
                                <Button block type='submit' color='default' size='middle' onClick={() => { navigate("/register") }} style={{ marginTop: 10 }}>
                                    注册
                                </Button>
                            </Grid.Item>
                            <Grid.Item>
                                <Button block type='submit' color='default' size='middle' onClick={() => { navigate("/login/phone") }} style={{ marginTop: 10 }}>
                                    验证码登录
                                </Button>
                            </Grid.Item>
                        </Grid>

                    </>
                }
            >

                <Form.Item name='accountCode' label="账号" rules={[{ required: true, message: '请输入账号' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='password' label="密码" rules={[{ required: true, message: '请输入密码' }]}>
                    <Input type="password" />
                </Form.Item>
            </Form>
        </>
    );
}
addRoute("/login", Page);
export default Page;
