

import React, { useEffect, useState, useImperativeHandle } from 'react'
import { Cascader, Button } from 'antd-mobile'
import module from 'util/module';
import { post } from 'util/http';

export const Page = ({
    url,
    param = { showRoot: false },
    value,
    label = "请选择",
    initData,
    onChange,
    visible = false,
    setVisible = () => { },
    ...nextProps
}) => {

    const [data, setData] = useState([]);

    const [extend, setExtend] = useState({
        label: null,
        value: null,
        fullLabel: [],
        fullValue: []
    })

    const formatData = (data) => {
        const newData = {
            value: data.code,
            label: data.name
        };
        if (data.children != null && data.children.length > 0) {
            newData.children = [];
            data.children.map((item) => {
                newData.children.push(formatData(item));
            })
        }
        return newData;
    }

    useEffect(() => {

        if (initData == null) {
            post(url, param).then(res => {
                var newData = [];
                res.data.map((item) => {
                    newData.push(formatData(item));
                })
                setData(newData);
            })
        } else {
            var newData = [];
            initData.map((item) => {
                newData.push(formatData(item));
            })
            setData(newData);
        }


    }, [])


    const formatValue = (extend) => {
        const res = {
            label: null,
            value: null,
            fullLabel: [],
            fullValue: []
        };
        if (extend.items.length > 0) {
            res.label = extend.items[extend.items.length - 1].label;
            res.value = extend.items[extend.items.length - 1].value;
            extend.items.map(item => {
                res.fullLabel.push(item.label);
                res.fullValue.push(item.value);
            })
        }
        return res;
    }
    return (
        <div>

            <Cascader
                options={data}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                value={value}
                onConfirm={(v, e) => {
                    const extend = formatValue(e);
                    setExtend(extend);
                    onChange(v, e, extend);
                }}
            >
                {items => {
                    if (items.every(item => item === null)) {
                        return label
                    } else {
                        return items.map(item => item?.label ?? '').join('')
                    }
                }}
            </Cascader >
        </div>
    );
}

export default Page;