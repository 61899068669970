

import React, { useState, useCallback, useEffect } from 'react'
import { Toast, Button } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';

const Page = ({
    business,
    getPhone,
    ...nextProps
}) => {
    const countSecond = 60;
    const [label, setLabel] = useState("发送验证码");
    const [disabled, setDisable] = useState(false);
    const countInfo = {
        time: countSecond,
        id: null
    }
    const startCount = () => {
        countInfo.id = setInterval(function () {
            if (countInfo.time == 1) {
                clearInterval(countInfo.id);
                countInfo.id = null;
                countInfo.time = countSecond;
                setLabel("发送验证码");
                setDisable(false)
            } else {
                countInfo.time = countInfo.time - 1;
                setLabel("剩余" + countInfo.time + "秒");
                setDisable(true)
            }
        }, 1000)
    }
    return (
        <Button
            {...nextProps}
            disabled={disabled}
            onClick={() => {
                getPhone().then(phone => {
                    if (phone != null) {
                        var captcha = new window.TencentCaptcha('190876691', function (res) {
                            if (res.ret == 0) {
                                post(module.auth + "/auth/v1.0/sms-code/send", {
                                    business: business,
                                    phone: phone,
                                    ticket: res.ticket,
                                    randstr: res.randstr
                                }).then(res => {
                                    Toast.show({
                                        content: '验证码发送成功',
                                    })
                                    startCount();
                                })
                            }

                        }, {});
                        captcha.show();
                    }
                });
            }}>
            {label}
        </Button>
    );
}

export default Page;