import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, NavBar, Button } from 'antd-mobile'
import { post } from 'util/http';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function Page() {


    const navigate = useNavigate();

    const [lawyer, setLawyer] = useState({
        attributes: {}
    });


    const loadAccountInfo = () => {
        post('/api/ailawyer/my/lawyer/info', {
        }).then(res => {
            setLawyer(res.data);
        })
    }

    useEffect(() => {
        loadAccountInfo();
        window.document.title = '律师账号';
    }, [])


    return (
        <div className='lawyer-page'>
            {/* <NavBar onBack={() => { navigate(-1) }}>律师账号</NavBar> */}

            <List header=''>


                <List.Item extra={<span>已开通</span>} >
                    律师账号
                </List.Item>


                <List.Item extra={<span>{moment(lawyer.createTime).format('YYYY-MM-DD')} </span>} >
                    开通时间
                </List.Item>

                <List.Item extra={
                    <span>
                        {moment(lawyer.endTime).format('YYYY-MM-DD')}
                    </span>} >
                    有效期至
                </List.Item>

            </List>
            <div className="pd-20">
                <Button color='success' onClick={() => {
                    navigate("/ailawyer/mine/lawyer/account/renewal")
                }} block>账号续费</Button>
            </div>
        </div>
    );
}
addPage("/ailawyer/mine/lawyer/account", Page);
export default Page;
