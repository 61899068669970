import React, { useState, useEffect, useRef } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import { getMetaLabel } from 'util/meta';
import ShareCanvas from 'component/ShareCanvas';
import PdfView from 'component/PdfView';
import { ReactSVG } from 'react-svg';

// import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
// import { Document, Page, pdfjs } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.js'

// import Viewer from '@phuocng/react-pdf-viewer';


function MyDocument() {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {


    }, [])



    return (

        <div style={{ height: '100vh' }}>

            <ReactSVG src="https://static-1306521133.cos.ap-beijing.myqcloud.com/bc3869cf28134c5a984ad31107c4460b/images/6ba4ea13ceed42b490bf188bbe04d9c4" className="svg-wrapper" />

        </div>
    );
}
addPage("/test/svg", MyDocument);
export default MyDocument;
