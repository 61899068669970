import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';

const Url = {
    download: (url) => {
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', '');
        a.click();
        // if (Url.isAndroid()) {
        //     window.open(url);
        // } else {
        //     window.location.href = url;
        // }
    },
    openPage: (page) => {
        if (window.floatPage == null) {
            window.floatPage = ReactDOM.createRoot(document.getElementById("float-page"));
            window.floatPage.render(page);
            document.getElementById("float-page").style.display = "block";
        } else {
            window.floatPage.render(page);
            document.getElementById("float-page").style.display = "block";
        }
    },
    closePage: () => {
        if (window.floatPage == null) {
            window.floatPage = ReactDOM.createRoot(document.getElementById("float-page"));
            window.floatPage.render(<></>);
            document.getElementById("float-page").style.display = "none";
        } else {
            window.floatPage.render(<></>);
            document.getElementById("float-page").style.display = "none";
        }
    },
    appendParam: (name, value) => {
        const history = createBrowserHistory();
        const newUrlParams = {};
        const search = new URLSearchParams(history.location.search);
        for (var key of search.keys()) {
            newUrlParams[key] = search.get(key);
        }
        newUrlParams[name] = value;
        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams(newUrlParams).toString()
        });
    },
    deleteParam: (name) => {
        const history = createBrowserHistory();
        const newUrlParams = {};
        const search = new URLSearchParams(history.location.search);
        for (var key of search.keys()) {
            newUrlParams[key] = search.get(key);
        }
        delete newUrlParams[name];
        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams(newUrlParams).toString()
        });
    },
    getParam: (name) => {
        const history = createBrowserHistory();
        const search = new URLSearchParams(history.location.search);
        return search.get(name)
    },
    isAndroid: () => {
        return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
    }

}
export default Url;