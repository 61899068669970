import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, NavBar, Button, Modal, Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import WeixinUtil from 'util/weixin';
import ScrollList from 'component/ScrollList'
import Url from 'util/url';

import './index.css'

function Page() {
    const navigate = useNavigate();

    const [formVisible, setFormVisible] = useState(false)
    const [protocolLawyer, setProtocolLawyer] = useState({})


    const [param, setParam] = useState({
        protocolCode: Url.getParam("protocolCode"),
    });


    const createPayment = (orderCode) => {
        WeixinUtil.goPay(orderCode, function (orderCode) {
            Toast.show({
                content: '支付完成,3秒后自动自动开始协议问答',
            })
            setTimeout(() => {
                navigate(`/ailawyer/my/protocol/detail?code=${orderCode}`)
            }, 3000)
        })
    }

    const createProtocolOrder = (protocolLawyer) => {
        setProtocolLawyer(protocolLawyer)
        setFormVisible(true);
        return;
        Modal.confirm({
            title: '协议制作',
            onConfirm: () => {
                post('/api/ailawyer/public/protocol/order/create', {
                    protocolCode: Url.getParam("protocolCode"),
                    lawyerCode: protocolLawyer.lawyerCode
                }).then(res => {
                    createPayment(res.data.code)
                })
            },
            content: <div style={{ padding: 20, color: "#3D445B" }}>
                <div>{protocolLawyer.remark}</div>
                <div style={{ marginTop: 20 }}>
                    制作律师服务费:<span style={{ color: 'red' }}>{protocolLawyer.unitPrice}</span>元/份
                </div>
            </div>,
        })
    }

    const [robotData, setRobotData] = useState({
        records: []
    });

    useEffect(() => {
        WeixinUtil.jsConfig();
        window.document.title = "选择律师"
    }, [])

    return (
        <div className="lawyer-page lawyer-chat-protocol-page" >
            {/* <NavBar onBack={() => { navigate(-1) }}>选择律师</NavBar> */}
            <ScrollList
                url={'/api/ailawyer/public/protocol/lawyer/search'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(lawyer => (

                        <div className="lawyer-item" key={lawyer.creatorCode} onClick={() => {
                            navigate(`/ailawyer/lawyer/detail?code=${lawyer.lawyerCode}`)
                        }}>

                            <div className="t1">
                                <div className="flex-row s1">
                                    <img src={"/api/ac/public/headimg/" + lawyer.creatorCode} className="headimg" />
                                    <div className="flex-row flex-1 justify-between">
                                        <div className='flex-row ml-10'>
                                            <div className="location-icon"></div>
                                            <div className="font-12 ">{lawyer.regionName ? lawyer.regionName : "全国"}</div>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-row">本月服务:<span className="font-13 fw-500 ml-5">{lawyer.lastMonthUse}</span></div>
                                            <div className="flex-row ml-10">累计服务:<span className="font-13 fw-500 ml-5">{lawyer.totalUse}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="s2">
                                    <div>{lawyer.lawyerName}</div>
                                    <div className="s">{lawyer.lawyerCompanyName}</div>
                                </div>
                            </div>

                            <div className="t2">
                                <div className="price-wraper">￥<span className="price">{lawyer.unitPrice}</span></div>
                                <button className="create-btn" onClick={(e) => {
                                    createProtocolOrder(lawyer)
                                    e.stopPropagation()
                                    return false;
                                }}>制作</button>
                            </div>
                        </div>
                    ))
                }

            </ScrollList>


            <Modal
                visible={formVisible}
                closeOnAction
                onClose={() => {
                    setFormVisible(false)
                }}
                content={<>
                    <div style={{ padding: 20, color: "#3D445B" }}>
                        <div>{protocolLawyer.remark}</div>
                        <div style={{ marginTop: 20 }}>
                            制作律师服务费:<span style={{ color: 'red' }}>{protocolLawyer.unitPrice}</span>元/份
                        </div>
                    </div>
                    <div className="flex-row mt-20" >
                        <Button color='default' block onClick={() => {
                            setFormVisible(false)
                        }}>取消</Button>
                        <Button color='success' block onClick={() => {
                            post('/api/ailawyer/public/protocol/order/create', {
                                protocolCode: Url.getParam("protocolCode"),
                                lawyerCode: protocolLawyer.lawyerCode
                            }).then(res => {
                                createPayment(res.data.code)
                            })
                        }}>提交</Button>
                    </div>
                </>}
            >
            </Modal>
        </div>
    );
}
addRoute("/ailawyer/chat/protocol", Page);
export default Page;
