import React, { useState, useEffect, useRef } from 'react'
import { addPage } from 'component/DynamicRotes';
import { List, Button, Ellipsis, Dialog, Modal, NavBar, Toast, SpinLoading } from 'antd-mobile'
import { Document, Page, pdfjs, Pagination, Outline } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import './PdfView.css'
import { DownCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { FaFileDownload } from 'react-icons/fa';
import Url from 'util/url';

pdfjs.GlobalWorkerOptions.workerSrc = window.PUBLIC_URL + '/static/js/pdf.worker.js'


function PdfView({
    url,
    ...nextProps
}) {


    const [numPages, setNumPages] = useState(null);

    const downloadFile = () => {
        Url.download(url);
    }
    return (
        <div className='pdf-view-page'>
            <Document file={url}
                onLoadSuccess={({ numPages }) => {
                    setNumPages(numPages)
                }}>
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={window.innerWidth}
                            height={window.innerHeight}
                        />
                    ),
                )}
            </Document>
            <FaFileDownload className="btn-download" onClick={downloadFile}></FaFileDownload>
        </div>
    );
}
export default PdfView;
