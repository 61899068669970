import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, NavBar, List } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import ImgUtil from 'util/img'
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import './index.css'

function Page() {

    const navigate = useNavigate();

    const [param, setParam] = useState({
        metaCode: "cmsArticle",
        params: [{
            attrCode: "channelCode",
            value: "help",
            operator: "="
        }],
        orders: []
    });


    const [robotData, setRobotData] = useState({
        records: []
    });

    useEffect(() => {
        window.document.title = "玩法&规则";
    }, [])

    return (
        <div className="lawyer-page lawyer-mine-help-page" >
            {/* <NavBar onBack={() => {
                navigate(-1)
            }} >帮助</NavBar> */}
            <ScrollList
                url={'/api/app/public/meta/data/search'}
                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(item => (

                        <List.Item key={item.id}
                            onClick={() => {
                                if (item.outsideUrl == null || item.outsideUrl == "") {
                                    navigate("/ailawyer/mine/help/detail?id=" + item.id);
                                } else {
                                    window.location.href = item.outsideUrl;
                                }
                            }}>
                            <div className="flex-row flex-justify-between">
                                <div className="font-14">{item.title}</div>
                                <div className="font-12">
                                    {/* {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')} */}
                                </div>
                            </div>

                        </List.Item>
                    ))
                }

            </ScrollList>
        </div>
    );
}
addRoute("/ailawyer/mine/help", Page);
export default Page;
