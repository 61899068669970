import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Image, NavBar, Ellipsis } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import CustomNavbar from '../navbar/index'
import { chatDateFormt } from 'util/date'
import './index.css'

function Page() {

    const metaCode = "aiChat";
    const navigate = useNavigate();
    const [swipedItem, setSwipedItem] = useState(null);
    const [startX, setStartX] = useState(0);
    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });

    const [robotData, setRobotData] = useState({
        records: []
    });

    const handleSwipeStart = (item, event) => {
        setStartX(event.touches ? event.touches[0].clientX : event.clientX);
        window.document.body.style.position = "fixed";
    };

    const handleSwipeEnd = () => {
        setStartX(0);
        window.document.body.style.position = "";
    };

    const handleSwipeMove = (item, event) => {
        const currentX = event.touches ? event.touches[0].clientX : event.clientX;
        const delta = currentX - startX;
        if (delta < -50) {
            if (swipedItem == null) {
                setSwipedItem(item);
            }
        } else if (delta > 50) {
            if (swipedItem != null) {
                setSwipedItem(null);
            }
        }
    };

    const handleDelete = (item, event) => {

        post('/api/ai/my/chat/delete', {
            chatCode: item.code
        }).then(res => {
            setParam({ ...param })
            setStartX(0);
            setSwipedItem(null);
        })
        event.stopPropagation()

    };

    useEffect(() => {
        window.document.title = "聊天";
    }, [])


    return (
        <div className="lawyer-page lawyer-chat-page"
            onClick={(item) => {
                setSwipedItem(null);
            }}>
            {/* <NavBar backArrow={null} >聊天</NavBar> */}
            <ScrollList
                url={'/api/app/my/meta/data/search'}

                param={param}
                setParam={setParam}
                data={robotData}
                setData={setRobotData}
            >
                {
                    robotData.records.map(item => (

                        <div className="chat-item" key={item.id}
                            style={{
                                position: "relative",
                                transform: swipedItem === item ? "translateX(-6rem)" : "translateX(0)",
                                transition: "transform 0.3s ease",
                                paddingRight: swipedItem === item ? "2rem" : "0",
                            }}
                            onClick={() => {
                                navigate("/ailawyer/chat/detail?chatCode=" + item.code);
                            }}>
                            <div className="flex-row t1"

                                onTouchStart={(event) => handleSwipeStart(item, event)}
                                onTouchMove={(event) => handleSwipeMove(item, event)}
                                onTouchEnd={handleSwipeEnd}
                                onMouseDown={(event) => handleSwipeStart(item, event)}
                                onMouseMove={(event) => handleSwipeMove(item, event)}
                                onMouseUp={handleSwipeEnd}
                            >
                                <Image src={"/api/ailawyer/public/robot/headimg/" + item.robotCode} className="headimg" />
                                <div className="flex-column flex-1">
                                    <div className="s1">{item.robotName}</div>
                                    <Ellipsis className="s2" direction='end' rows={2} content={item.lastMessage} />
                                </div>
                                <div className="s3">
                                    {chatDateFormt(item.updateTime)}
                                </div>


                            </div>

                            {swipedItem === item && (
                                <button
                                    onClick={(event) => handleDelete(item, event)}
                                    className="delete-btn"
                                >
                                    删除
                                </button>
                            )}
                        </div>
                    ))
                }

            </ScrollList >
            <CustomNavbar />
        </div >
    );
}
addRoute("/ailawyer/chat", Page);
export default Page;
