import moment from 'moment';

export function format(date, fmt) {
    if (date == null || date == '') {
        return "";
    } else {
        return moment(date).format(fmt);
    }
}

export function chatDateFormt(date) {
    if (date == null || date == '') {
        return "";
    } else {
        /** if date is today */
        if (moment(date).isSame(moment(), 'day')) {
            return moment(date).format('HH:mm');
        } else {
            /**如果超过1年，显示年月日 */
            if (moment().diff(moment(date), 'years') > 0) {
                return moment(date).format('YYYY-MM-DD');
            } else {
                return moment(date).format('MM-DD');
            }
        }
    }
}