import React, { useState, useEffect } from 'react'
import { addPage } from 'component/DynamicRotes';
import { Image, List, Button, Ellipsis, Dialog, Modal, NavBar, Toast } from 'antd-mobile'
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import ScrollList from 'component/ScrollList'
import moment from 'moment'
import { getMetaLabel } from 'util/meta';


import Url from 'util/url';



function Page() {

    const metaCode = "paifuWorker";

    const navigate = useNavigate();

    const [param, setParam] = useState({
        metaCode: metaCode
    });


    const [member, setMember] = useState({})




    useEffect(() => {

        post('/api/crm/my/info', {
        }).then(res => {
            setMember(res.data);
        })

        post("/api/weixin/js/config", {
            url: window.location.href
        }).then(res => {
            window.wx.config({
                debug: false,
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'showAllNonBaseMenuItem', 'showMenuItems'],
                ...res.data
            })
        })

        window.wx.ready(function () {
            post('/api/crm/my/info', {
            }).then(res => {
                const link = window.location.origin + "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind/" + res.data.referralCode;
                window.wx.updateAppMessageShareData({
                    title: '派服-专业地坪信息服务平台', // 分享标题
                    desc: '派服-专业地坪信息服务平台', // 分享描述
                    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: 'https://static-1306521133.cos.ap-beijing.myqcloud.com/paifu/paifu-console/build/static/media/logo.8a654adb4c209072e47f.png', // 分享图标
                    success: function () {
                    }
                })
            })
        })


    }, [])

    const createOrder = () => {
        post("/api/ec/public/order/create", {
            description: "积分购买",
            orderProducts: [{
                productCode: "a5d7e4029e284fe48064969d52b02f83",
                number: 1
            }]
        }).then(res => {
            createPayment(res.data.code)
        })
    }

    const createPayment = (orderCode) => {
        post("/api/ec/weixin/payment/create", {
            orderCode: orderCode
        }).then(res => {
            const responseData = JSON.parse(res.data.responseData);
            responseData.timestamp = responseData.timeStamp;
            responseData.success = function (res) {
                Modal.confirm({
                    content: '支付完成，前往查看积分。',
                    okText: '查看',
                    cancelText: '取消',
                    onConfirm: function () {
                        navigate("/personal/points");
                    }
                });
            }
            window.wx.chooseWXPay(responseData);
        })
    }

    return (
        <>
            <NavBar onBack={() => { navigate(-1) }}>获取积分</NavBar>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>邀请工友获取积分</h2>
                <div style={{ marginBottom: 10 }}>成功邀请工友注册,每个获取100积分</div>
                <Button block color='primary' onClick={() => {
                    // const link = window.location.origin + "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind/" + query.get("referralCode");
                    // window.wx.updateAppMessageShareData({
                    //     title: '派服-专业地坪信息服务平台', // 分享标题
                    //     desc: '派服-专业地坪信息服务平台', // 分享描述
                    //     link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    //     imgUrl: 'https://static-1306521133.cos.ap-beijing.myqcloud.com/paifu/paifu-console/build/static/media/logo.8a654adb4c209072e47f.png', // 分享图标
                    //     success: function () {
                    //         // 设置成功
                    //         window.wx.showMenuItems({
                    //             menuList: ["menuItem:share:appMessage"]
                    //         });
                    //     }
                    // })
                    Toast.show({
                        content: '点击右上角，分享给朋友',
                    })
                }} >去邀请</Button>
            </div>

            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值30积分30元,1500积分1000元</div>
                <Button block color='primary' onClick={() => {
                    navigate("/personal/points/buy");
                }} >去充值</Button>
            </div>


            {/* <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>积分充值</h2>
                <div style={{ marginBottom: 10 }}>积分充值30积分29元</div>
                <Button block color='primary' onClick={() => {
                    createOrder();
                }} >去充值</Button>
            </div> */}
            <div className='pd-5-20'>
                <h2 style={{ margin: "10px 0px" }}>发布工程</h2>
                <div style={{ marginBottom: 10 }}>发布工程信息免费+50个积分</div>
                <Button block color='primary' onClick={() => { navigate("/project/form"); }} >去发布</Button>
            </div>
        </>
    );
}
addPage("/personal/points/obtain", Page);
export default Page;
