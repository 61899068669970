import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { Input, NavBar, Form, Button, Toast, Collapse } from 'antd-mobile'
import { post } from 'util/http';

import { useNavigate } from 'react-router-dom';
import RichTextEditor from 'component/RichTextEditor'
import ImageUpload from 'component/ImageUpload'
import Url from 'util/url'
import PdfView from 'component/PdfView';
import './index.css'
import FileUpload from 'component/FileUpload';
import { DownCircleFilled, DownCircleOutlined, UpCircleFilled, UpCircleOutlined } from '@ant-design/icons';
function Page() {

    const navigate = useNavigate();
    const [pageForm] = Form.useForm()
    const [uploadForm] = Form.useForm()

    const [questions, setQuestions] = useState([]);
    const [protocolOrder, setProtocolOrder] = useState({
    });


    // const showPdfView = (ticket) => {
    //     // Url.openPage(<div>
    //     //     <PdfView url={"/api/ailawyer/protocol/order/file/preview?protocolOrderCode=" + Url.getParam("code")}
    //     //     ></PdfView>
    //     // </div>)

    // }
    const previewProtocolOrder = () => {

        pageForm.validateFields().then(values => {
            post('/api/ailawyer/protocol/order/save-and-preview', {
                protocolOrderCode: Url.getParam("code"),
                resultContent: values.resultContent,
                onlySave: true
            }).then(res => {
                Url.download("/api/ailawyer/protocol/order/file/preview?ticket=" + res.data)
            })
        })
    }


    const loadQuestions = (orderCode) => {
        post('/api/ailawyer/protocol/order/answer/list-by-lawyer', {
            protocolOrderCode: orderCode
        }).then(res => {
            setQuestions(res.data.questions);
            const questionMap = {};
            res.data.questions.forEach(item => {
                questionMap[item.question] = item.answer;
            })
            pageForm.setFieldsValue(questionMap);
        })
    }

    const approvalProtocolOrder = () => {
        pageForm.validateFields().then(values => {
            post('/api/ailawyer/protocol/order/approval-by-lawyer', {
                protocolOrderCode: Url.getParam("code"),
                resultContent: values.resultContent,
                resultFile: values.resultFile,
                onlySave: false
            }).then(res => {
                Toast.show({
                    content: '操作成功',
                })
                navigate(-1)
            })
        })
    }

    const downloadProtocol = () => {
        post('/api/app/file/v1.0/file/download', {
            fileCode: protocolOrder.resultFile
        }).then(res => {
            if (Url.isAndroid()) {
                window.open(res.data);
            } else {
                window.location.href = res.data;
            }
        })
    }


    useEffect(() => {
        post(' /api/ailawyer/protocol/order/get-by-lawyer', {
            protocolOrderCode: Url.getParam("code")
        }).then(res => {
            pageForm.setFieldsValue(res.data);
            setProtocolOrder(res.data);
        })

        loadQuestions(Url.getParam("code"))
    }, [])


    return (
        <div className="lawyer-page lawyer-mine-lawyer-order-detail-page" >
            <Collapse defaultActiveKey={['2']}>
                <Collapse.Panel key='1' title='用户信息' arrow={active => (active ? <span className="font-13 color-green">收起<DownCircleOutlined className="ml-5" ></DownCircleOutlined></span> : <span className="font-13 color-green ">展开<UpCircleOutlined className="ml-5" /></span>)}>
                    {questions.map((item, index) => (
                        <Form.Item
                            name={item.question}
                            label={item.question}
                            value={item.answer}
                            key={index}
                        >
                            {item.answer}
                            {/* <Input value={item.answer} readOnly={true} /> */}
                        </Form.Item>
                    ))}
                    <div className="btn-submit" onClick={() => {
                        Toast.show({
                            content: '待开发',
                        })
                    }} style={{ margin: '2rem auto 1rem' }}>
                        导出excel
                    </div>
                </Collapse.Panel>
                <Collapse.Panel key='2' title='生成的文书'
                    arrow={active => (active ? <span className="font-13 color-green">收起<DownCircleOutlined className="ml-5" ></DownCircleOutlined></span> : <span className="font-13 color-green ">展开<UpCircleOutlined className="ml-5" /></span>)}>
                    <Form
                        form={pageForm} >
                        <Form.Item name="code" style={{ display: 'none' }}>
                            <Input placeholder='' />
                        </Form.Item>

                        <Form.Item name='resultContent' >
                            <RichTextEditor
                                placeholder='请输入荣誉内容'
                                maxLength={500}
                                rows={4}
                                readOnly={protocolOrder.status == 'finish'}
                                showCount
                            />
                        </Form.Item>

                        <Form.Item name='resultFile' label="盖章版文书(非必须)" >
                            <FileUpload readOnly={protocolOrder.status == 'finish'} />
                        </Form.Item>
                        < div className='pd-10'>
                            {protocolOrder.status == 'approvaling' &&
                                <div className="btn-submit" onClick={() => { approvalProtocolOrder() }} style={{ margin: '0 auto' }}>
                                    提交并发送文书
                                </div>
                            }
                            {protocolOrder.status == 'approvaling' && <>< Button block color='default' size='middle' onClick={() => { previewProtocolOrder() }} style={{ marginTop: 10, border: 0 }}>
                                预览&下载PDF文书
                            </Button>
                            </>}

                            {protocolOrder.status == 'finish' &&
                                <div className="btn-submit" onClick={() => { downloadProtocol() }} style={{ margin: '0 auto' }}>
                                    下载PDF文书
                                </div>
                            }
                        </div>

                    </Form>
                </Collapse.Panel>

                {/* <Collapse.Panel key='3' title='手动上传盖章版'>
                    <Form
                        form={pageForm} >

                        <Form.Item name="code" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='file' >
                            <ImageUpload />
                        </Form.Item>
                        <div className='pd-10'>

                            <div className="btn-submit" onClick={() => {
                                Toast.show({
                                    content: '待开发',
                                })
                            }} style={{ margin: '0 auto' }}>
                                提交并发送文书
                            </div>

                        </div>

                    </Form>
                </Collapse.Panel> */}

            </Collapse>




        </div >
    );
}
addRoute("/ailawyer/mine/lawyer/order/detail", Page);
export default Page;