import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { ResultPage } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';

import Url from 'util/url';

function Page() {
    const navigate = useNavigate();
    let points = Url.getParam("points");
    if (points == null) {
        points = "10";
    }
    return (
        <ResultPage
            status='warning'
            title='签到提示'
            description={'您今日已签到'}
            secondaryButtonText='返回个人中心'
            onSecondaryButtonClick={() => {
                navigate("/personal")
            }}
        />
    );
}
addRoute("/personal/sign/error", Page);
export default Page;
