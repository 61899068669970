import React, { useState, useEffect } from 'react'
import { addPage, addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { Image, NavBar, Toast, Ellipsis, Button, Modal, Form, Input } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import WeixinUtil from 'util/weixin';
import Url from 'util/url';
import './index.css'
import LaywerItem from 'pages/lawyer/component/lawyer-item/index';


function Page() {
    const metaCode = "ailawyerRobot";
    const navigate = useNavigate();

    const [pageForm] = Form.useForm();

    const [questions, setQuestions] = useState([]);

    const [protocolOrder, setProtocolOrder] = useState({
    });
    const [robot, setRobot] = useState({
    });
    const [lawyer, setLawyer] = useState({});
    const [lawyerImages, setLawyerImages] = useState([]);

    const getRobot = (robotCode) => {
        post('/api/ailawyer/public/robot/get', {
            code: robotCode,
        }).then(res => {
            setRobot(res.data)
        })
    }

    const submitForm = () => {
        const data = pageForm.getFieldsValue();
        const url = '/api/ailawyer/my/protocol/order/submit';
        post(url, {
            protocolOrderCode: Url.getParam("code"),
            answers: data
        }).then(res => {
            Toast.show({
                content: '提交成功，等待系统制作文书和律师审核',
            })
            // loadProtocolOrder(Url.getParam("code"))
            navigate("/ailawyer/mine/protocol")
        })
    }
    const loadQuestions = (orderCode) => {
        post('/api/ailawyer/my/protocol/order/answer/list', {
            protocolOrderCode: orderCode
        }).then(res => {
            setQuestions(res.data.questions);
            const questionMap = {};
            res.data.questions.forEach(item => {
                questionMap[item.question] = item.answer;
            })
            pageForm.setFieldsValue(questionMap);
        })
    }

    const createPayment = () => {
        WeixinUtil.goPay(protocolOrder.code, function (orderCode) {
            Toast.show({
                content: '支付完成,5秒后自动刷新订单状态',
            })
            setTimeout(() => {
                reloadProtocolOrder(orderCode)
            }, 5000)
        })
    }

    const reloadProtocolOrder = (orderCode) => {
        post('/api/ailawyer/my/protocol/order/get', {
            protocolOrderCode: orderCode,
        }).then(res => {
            setProtocolOrder(res.data);
        })
    }

    const loadProtocolOrder = (orderCode) => {
        post('/api/ailawyer/my/protocol/order/get', {
            protocolOrderCode: orderCode,
        }).then(res => {
            getRobot(res.data.robotCode)
            setProtocolOrder(res.data);
            loadLawyerInfo(res.data.lawyerCode)
        })
    }
    const downloadProtocol = () => {
        post('/api/app/file/v1.0/file/download', {
            fileCode: protocolOrder.resultFile
        }).then(res => {
            if (Url.isAndroid()) {
                window.open(res.data);
            } else {
                window.location.href = res.data;
            }
        })
    }


    const loadLawyerInfo = (lawyerCode) => {
        post('/api/ailawyer/public/lawyer/get', {
            code: lawyerCode
        }).then(res => {
            setLawyer(res.data)
        })
    }


    useEffect(() => {
        const code = Url.getParam("code");
        if (code) {
            loadProtocolOrder(code)
            loadQuestions(code)
        }
    }, [Url.getParam("code")])


    useEffect(() => {
        WeixinUtil.jsConfig();
    }, [])
    return (
        <div className="lawyer-page mine-protocol-detail-page" >
            {/* <NavBar onBack={() => { navigate(-1) }}>{protocolOrder.protocolName}</NavBar> */}

            {/* <div className="lawyer-item" key={protocolOrder.code}>

                <div className="t1">
                    <div className="flex-row s1">
                        <img src={"/api/ac/public/headimg/" + protocolOrder.lawyerCode} className="headimg" />
                        <div className="flex-row flex-1 justify-between">
                            <div className='flex-row ml-10'>
                                <div className="location-icon"></div>
                                <div className="font-12 ">{lawyer.regionName ? lawyer.regionName : "全国"}</div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-row">本月服务:<span className="font-13 fw-500 ml-5">{lawyer.lastMonthUse}</span></div>
                                <div className="flex-row ml-10">累计服务:<span className="font-13 fw-500 ml-5">{lawyer.totalUse}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="s2 flex-row justify-between">
                        <div className="flex-row">
                            <div>{lawyer.name}</div>
                            <div className="s">{lawyer.companyName}</div>
                        </div>
                        <div className="share-icon" onClick={() => {
                        }}></div>
                    </div>
                </div>

            </div> */}

            <LaywerItem lawyer={lawyer} />

            <div className="protocol-tip">制作文书需要了解背景情况，请准确回答以下问题</div>
            <div className='protocol-content'>
                <Form form={pageForm} layout="vertical">
                    {questions.map((item, index) => (
                        <Form.Item
                            name={item.question}
                            label={item.question}
                            value={item.answer}
                            key={index}
                        >
                            <Input value={item.answer} readOnly={protocolOrder.status != 'paySuccess'} />
                        </Form.Item>
                    ))}

                </Form>

                <div className="protocol-submit">
                    {protocolOrder.status == 'create' && <Button color='primary' block={true} onClick={() => { createPayment() }}>支付</Button>}
                    {protocolOrder.status == 'paySuccess' && <Button color='primary' block={true} onClick={() => { submitForm() }}>提交</Button>}
                    {protocolOrder.status == 'making' && <Button color="warning" block={true}>协议制作中</Button>}
                    {protocolOrder.status == 'approvaling' && <Button color="warning" block={true}>律师审核中</Button>}
                    {protocolOrder.status == 'finish' && <Button color="success" block={true} onClick={downloadProtocol}>下载PDF文书</Button>}
                </div>
            </div>


        </div>
    );
}
addRoute("/ailawyer/my/protocol/detail", Page);
export default Page;
