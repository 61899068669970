import React, { useState, useEffect } from 'react'
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import { NavBar, Toast, Button, Form, Input, Radio } from 'antd-mobile'
import { useNavigate } from 'react-router-dom';
import CaptchaButton from 'component/CaptchaButton';
import Privacy from './privacy'
import Aggrement from './agreement';
import Url from 'util/url';
import './index.css'

function Page() {

    const [pageForm] = Form.useForm()

    const [agreementChecked, setAgreementChecked] = useState(false);
    const pathname = window.location.pathname;

    const submitRegister = () => {
        pageForm.validateFields().then((values) => {
            if (!agreementChecked) {
                Toast.show("请阅读并同意用户协议！");
                return;
            } else {
                post("/api/weixin/auth/v1.0/bind/account", {
                    referralCode: pathname.substring(6, pathname.length),
                    ...values
                }).then(res => {
                    Toast.show({
                        content: '注册成功',
                    })
                    window.location.href = (window.APP_CONFIG.defaultPath);
                })
            }
        })
    }

    useEffect(() => {
        window.document.title = "绑定手机号";
    }, [])

    return (
        <div className="lawyer-page lawyer-login-page" >
            {/* <NavBar
                back="首页"
                backArrow={false}
                onBack={() => { navigate(window.APP_CONFIG.defaultPath) }}>注册</NavBar> */}
            <Form
                form={pageForm}
                layout='horizontal'
                footer={
                    <>
                        <Button block type='submit' color='success' size='middle' onClick={submitRegister} style={{ marginTop: 10 }}>
                            提交
                        </Button>
                        <div className="aggrement-privacy">
                            <Radio checked={agreementChecked} onChange={(v) => { setAgreementChecked(v) }}>已阅读及同意
                                <a className="s2" onClick={() => {
                                    Url.openPage(<Aggrement></Aggrement>);
                                }}>《律伴用户协议》</a>&nbsp;
                                <a className="s2" onClick={() => {
                                    Url.openPage(<Privacy></Privacy>);
                                }}>《隐私政策》</a>
                            </Radio>
                        </div>

                    </>
                }
            >
                <div className='flex-column flex-align-center' style={{ padding: "86px 0px" }}>
                    <img src={window.PUBLIC_URL + '/static/images/ailawyer/logo-login.png'} style={{ width: 145, height: 32 }}></img>
                    <div className="font-26">有伴律师</div>
                </div>

                <div >
                    <Form.Item name='phone' rules={[
                        { required: true, message: '请输入手机号' },
                        {
                            validator: (_, value) => {
                                return !(/^1[3-9]\d{9}$/).test(value) ? Promise.reject('手机号格式不正确') : Promise.resolve();
                            }
                        },
                    ]}>
                        <Input placeholder='请输入手机号' type="number" />
                    </Form.Item>
                    <Form.Item className="mt-10" name='smsCode'
                        rules={[{ required: true, message: '请输入短信验证码' }]}
                        extra={<CaptchaButton fill='none' size="small" business="bind" getPhone={() => {
                            return pageForm.validateFields(['phone']).then(res => {
                                return res.phone;
                            }, err => {
                                return null;
                            })
                        }}></CaptchaButton>} >
                        <Input placeholder='请输入验证码' />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}
addRoute("/ailawyer/bind", Page);
export default Page;
