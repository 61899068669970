import React, { useState } from 'react'
import { addPage } from 'component/DynamicRotes';

function Page() {

    return (
        <div className='text-center'>
            北京希禾云科技有限公司
        </div>
    );
}
addPage("*", Page);
export default Page;
