

import React, { useState, useCallback, useEffect } from 'react'
import { DatePicker, Input } from 'antd-mobile'
import moment from 'moment'

const Page = ({
    children,
    value,
    format = "YYYY-MM-DD",
    onChange = () => {
    },
    ...nextProps
}) => {
    const [visible, setVisible] = useState(false)
    var dateValue = null;
    if (typeof value == 'string') {
        dateValue = moment(value, format).toDate();
    } else if (typeof value == 'object') {
        dateValue = value;
    }


    useEffect(() => {
    }, [])

    return (

        <div>
            <Input onFocus={() => {
                setVisible(true)
            }} value={dateValue ? moment(dateValue).format(format) : ""} />
            <DatePicker
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                value={dateValue}
                onConfirm={val => {
                    const newValue = moment(val).format(format);
                    onChange(newValue);
                }}
                {...nextProps}
            />
        </div>
    );
}

export default Page;
